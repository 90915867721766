import { Button, Checkbox, Modal, Radio, notification } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import AppButton from '../components/AppButton';
import { Link, useHistory } from 'react-router-dom';
import AppLayout from '../layout/AppLayout';
import Cart, { CheckCustomerDiscountProps } from '../components/Cart';
import CheckoutAuth from '../components/CheckoutAuth';
import CheckoutDelivery from '../components/CheckoutDelivery';
import FormInputCustom from '../components/FormInputCustom';
import CheckoutPickup, {
  DataCheckoutGuest,
} from '../components/CheckoutPickup';
import useOrder, { EOrderType } from '../hooks/useOrder';
import ModalSelectOrderType from '../components/ModalSelectOrderType';
import useCart from '../hooks/useCart';
import useAuth from '../hooks/useAuth';
import { EShipmentMethod, ITransactionGuest } from '../types/transaction.type';
import { httpRequest } from '../helpers/api';
import ModalOrderCreated from '../components/ModalOrderCreated';
import { ChevronLeft } from 'react-feather';
import { CheckCustomerDiscount } from '../types/customer.type';
import Payment, {
  EmbedPaymentProductProps,
  EmbedPaymentProps,
} from '../components/Payment';
import { FetchAllAppConfigResponse } from '../types/appConfig.type';

type NotificationType = 'success' | 'info' | 'warning' | 'error';

const Checkout: React.FC = () => {
  const history = useHistory();
  const {
    orderType,
    checkoutAsGuest,
    currSelectedLocation,
    tmpOrderType,
    scheduleDeliveries,
    deliveryPostcode,
    setIsShowModalOrderType,
    setTmpOrderType,
    setCurrSelectedLocation,
    setDataForOrderCreatedModal,
    setIsShowModalOrderCreated,
    fetchSchedules,
    fetchScheduleDeliveries,
  } = useOrder();
  const {
    localCarts,
    cartGroup,
    isShowModalCartWarning,
    unavailableCartItems,
    checkCartItems,
    subTotal,
    setIsShowModalCartWarning,
    removeAllCartItems,
    checkRemoveAllCartItems,
    metaDiscountCartGroup,
    totalPay,
  } = useCart();
  const { isLoggedIn, user } = useAuth();
  const [loadingSubmit, setLoadingSubmit] = useState<boolean>(false);

  const [showPaymentEmbed, setShowPaymentEmbed] = useState<boolean>(false);
  const [currDataPayments, setCurrDataPayments] =
    useState<Omit<EmbedPaymentProps, 'handlePaymentSuccess'>>();

  const scheduleRef: any = useRef(false);

  const handleChangeOrderType = async (type: string | number) => {
    let currOrderType: EOrderType;

    if (type === EOrderType.delivery) {
      currOrderType = EOrderType.delivery;
    } else {
      currOrderType = EOrderType.pickup;
    }

    if (type === EOrderType.delivery) {
      setTmpOrderType(currOrderType);
    } else if (type === EOrderType.pickup) {
      setCurrSelectedLocation(undefined);
      setTmpOrderType(currOrderType);
    }

    if (localCarts && localCarts.length > 0) {
      const res = checkRemoveAllCartItems();
      if (res === 0) {
        setIsShowModalOrderType(true);
      } else {
        setIsShowModalCartWarning(true);
      }
    } else {
      setIsShowModalOrderType(true);
    }
  };

  const handleRemoveCartItems = () => {
    removeAllCartItems();
    handleCancelRemoveCartItems();
    setIsShowModalOrderType(true);
  };

  const handleCancelRemoveCartItems = () => {
    setIsShowModalCartWarning(false);
  };

  useEffect(() => {
    // if (!scheduleRef.current) {
    //   console.log('fetch');
    // }
    const init = async () => {
      console.log('init');
      await fetchScheduleDeliveries();
      await fetchSchedules();

      // scheduleRef.current(true);
    };
    init();
  }, []);

  const handleClickCheckout = async (data?: any) => {
    setLoadingSubmit(true);
    try {
      let transactionDatas: ITransactionGuest[] = [];

      let dataCustomer = {
        fullName:
          isLoggedIn && user
            ? user.fullName
            : data
            ? data.firstName + ' ' + data.lastName
            : '-',
        phoneNumber:
          isLoggedIn && user ? user.phoneNumber : data ? data.phoneNumber : '-',
        email: isLoggedIn && user ? user.email : data ? data.email : '-',
      };

      for (const cart of cartGroup) {
        let metaPickup: any;
        let metaDelivery: any;

        if (orderType === EOrderType.pickup && currSelectedLocation) {
          metaPickup = {
            pickupTimeStart:
              currSelectedLocation.timeframe.split('-').length > 0
                ? currSelectedLocation?.timeframe.split('-')[0]
                : currSelectedLocation?.timeframe,
            pickupTimeEnd:
              currSelectedLocation.timeframe.split('-').length > 0
                ? currSelectedLocation?.timeframe.split('-')[1]
                : currSelectedLocation?.timeframe,
            pickupFromLocationId: currSelectedLocation.locationId,
            pickupFromLocationName: currSelectedLocation.locationName,
            pickupFromLocationAddress: currSelectedLocation.locationAddress,
          };
        }

        if (orderType === EOrderType.delivery) {
          const timeFrame = data.deliveryTimeframes.map((item: any) => ({
            scheduleAt: item.scheduleAt,
            selectedTimeframe: item.selectedTimeframe,
          }));
          metaDelivery = {
            deliveryScheduleTimeframe: timeFrame,
            deliveryAddress: data.address,
            deliveryAddressNote: data.addressNote,
            deliveryAddressState: data.state,
            deliveryAddressPostcode: data.postcode,
            deliveryAddressSuburb: data.suburb,
            deliveryInformation: data.deliveryInformation || '',
            deliveryCoords: data.coords || null,
          };
        }

        let currDeliveryFee = cart.deliveryFeeCartGroup;

        let discount = 0;
        let metaDiscount = null;
        const currentDiscount = metaDiscountCartGroup.currentDiscounts.find(
          (item) => item.scheduleAt === cart.scheduleAt
        );

        if (currentDiscount) {
          discount = currentDiscount.discount.discountNominal;
          metaDiscount = {
            discountNominal: currentDiscount.discount.discountNominal,
            discountName: currentDiscount.discount.discountName,
          };
        }

        transactionDatas.push({
          groupedId: '',
          // deliveryFee: deliveryFee(),
          deliveryFee: currDeliveryFee,
          metaBuyer: {
            fullName: dataCustomer.fullName,
            email: dataCustomer.email,
            phoneNumber: dataCustomer.phoneNumber,
          },
          items: cart.cartItems.map((item) => ({
            productId: item.productId,
            productVariantId: item.productVariantId || '',
            productPrice: item.productDetail
              ? item.productDetail.variant.price
              : 0,
            metaProduct: {
              ...item.productDetail,
              menuItems: item.meta.menuItems || null,
            },
            quantity: item.quantity,
          })),
          shipment: {
            cost: 0,
            method:
              orderType === EOrderType.pickup
                ? EShipmentMethod.PICKUP
                : EShipmentMethod.DELIVERY,
            scheduledAt: new Date(cart.scheduleAt),
            shipmentVendorId:
              orderType === EOrderType.pickup
                ? (currSelectedLocation?.locationId as string)
                : '',
            meta:
              orderType === EOrderType.pickup
                ? metaPickup
                : {
                    ...metaDelivery,
                    deliveryScheduleTimeframe:
                      metaDelivery.deliveryScheduleTimeframe.length > 0
                        ? metaDelivery.deliveryScheduleTimeframe
                        : {
                            scheduleAt: cart.scheduleAt,
                            selectedTimeframe: cart.scheduleAt,
                          },
                  },
          },
          discount,
          metaDiscount,
        });
      }

      const dataSent = {
        fullName: dataCustomer.fullName,
        email: dataCustomer.email,
        phoneNumber: dataCustomer.phoneNumber,
        bulk: transactionDatas,
        locationCode:
          orderType === EOrderType.pickup && currSelectedLocation
            ? currSelectedLocation.locationCode
            : 'DELIVERY',
        metaTransaction: {
          totalNeedToPay: totalPay,
          discount: metaDiscountCartGroup.totalDiscount || null,
        },
      };

      console.log('transactionDatas 1', dataSent);

      const res: any = await httpRequest.post(
        checkoutAsGuest && !isLoggedIn
          ? '/transactions/bulk/as-guest'
          : '/transactions/bulk',
        {
          ...dataSent,
        }
      );

      console.log('res', res.data);

      if (res && res.data) {
        const result = res.data.payload.results[0];
        const resultAirwallex = res.data.payload.airwallexResponse;

        // history.replace('/#home');
        // setDataForOrderCreatedModal({
        //   NavigateURL: res.data.payload?.payment?.metaPaymentDetail?.url,
        //   email: dataCustomer.email,
        //   locationName:
        //     transactionDatas[0].shipment.meta.pickupFromLocationName || '',
        //   locationAddress:
        //     transactionDatas[0].shipment.meta.pickupFromLocationAddress || '',
        //   locationContact:
        //     transactionDatas[0].shipment.meta.pickupFromLocationContact || '',
        //   totalPrice: totalPay(),
        //   paymentGroupedId: res.data.payload?.payment?.groupedId,
        // });
        // setShowPaymentEmbed(true);
        // const urlPayment = res.data.payload?.payment?.metaPaymentDetail?.url;
        // // window.location.reload();
        // if (urlPayment) {
        //   // code 1 -> not working
        //   // window.open(urlPayment);

        //   // code 2 -> work on specific case
        //   // const a = document.createElement('a');
        //   // a.setAttribute('href', urlPayment);
        //   // a.setAttribute('target', '_blank');
        //   // a.click();

        //   // code 3 ->
        //   setTimeout(() => {
        //     window.open(urlPayment, '_blank');
        //   }, 1000);
        // }
        // setIsShowModalOrderCreated(true);

        // const dataPayments: EmbedPaymentProductProps[] = result.items.map(
        //   (item: any) => {
        //     return {
        //       imageUrl:
        //         item.metaProduct.images && item.metaProduct.images.length > 0
        //           ? item.metaProduct.images[0].imageUrl
        //           : '',
        //       name: item.metaProduct.name,
        //       description: result.groupedId,
        //       unitPrice: item.productPrice,
        //       qty: item.quantity,
        //     };
        //   }
        // );

        // console.log('dataPayments', dataPayments);

        setCurrDataPayments({
          id: resultAirwallex.id,
          client_secret: resultAirwallex.client_secret,
          currency: resultAirwallex.currency,
          groupedId: result.groupedId,
        });

        setShowPaymentEmbed(true);

        history.push(
          `/checkout?gId=${result.groupedId}&trxId=${result.transactionId}&awxId=${resultAirwallex.id}`
        );
      }
    } catch (error) {
      console.error('failed submit ', error);
    } finally {
      setLoadingSubmit(false);
    }
  };

  function onHandlePaymentSuccess(groupedId: string) {
    removeAllCartItems();
    localStorage.removeItem('location');
    localStorage.removeItem('orderType');
    const isPartialDelivery = localStorage.getItem('partialDelivery');
    isPartialDelivery && localStorage.removeItem('partialDelivery');
    localStorage.removeItem('dateAddToCart');
    localStorage.removeItem('dataUnavailableSchedule');
    localStorage.removeItem('deliveryPostcode');

    setDataForOrderCreatedModal({
      totalPrice: totalPay,
      paymentGroupedId: groupedId,
    });

    setIsShowModalOrderCreated(true);
    history.replace('/#home');
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [orderType]);

  return (
    <AppLayout>
      <div
        className='homepage-section checkout-container'
        style={{ padding: '20px 0' }}
      >
        <div className='checkout-wrapper-left'>
          <h2
            style={{
              fontFamily: "'Poppins', sans-serif",
              fontWeight: 600,
              fontSize: 24,
            }}
          >
            Checkout
          </h2>

          {!checkoutAsGuest && !isLoggedIn ? (
            <CheckoutAuth />
          ) : (
            <>
              <div>
                {/* <FormInputCustom label='Order Type'>
                  <div className={"order-type"}>
                    <Radio.Group
                      onChange={(e) => handleChangeOrderType(e.target.value)}
                      value={orderType}
                    >
                      <Radio
                        style={{
                          border:
                            orderType === EOrderType.pickup
                              ? "2px solid #ff4a50"
                              : "1px solid #d5dce1",
                        }}
                        value={EOrderType.pickup}
                      >
                        Pickup
                      </Radio>
                      <Radio
                        style={{
                          border:
                            orderType === EOrderType.delivery
                              ? "2px solid #ff4a50"
                              : "1px solid #d5dce1",
                        }}
                        value={EOrderType.delivery}
                      >
                        Delivery
                      </Radio>
                    </Radio.Group>
                  </div>
                </FormInputCustom> */}

                {!showPaymentEmbed && orderType === EOrderType.pickup ? (
                  <CheckoutPickup
                    isLoading={loadingSubmit}
                    onCheckout={(data) => handleClickCheckout(data)}
                  />
                ) : !showPaymentEmbed && orderType === EOrderType.delivery ? (
                  <CheckoutDelivery
                    isLoading={loadingSubmit}
                    onCheckout={(data) => handleClickCheckout(data)}
                  />
                ) : showPaymentEmbed && currDataPayments ? (
                  <Payment
                    id={currDataPayments.id}
                    client_secret={currDataPayments.client_secret}
                    currency={currDataPayments.currency}
                    groupedId={currDataPayments.groupedId}
                    handlePaymentSuccess={onHandlePaymentSuccess}
                  />
                ) : (
                  false
                )}
              </div>
            </>
          )}
        </div>
        <div className='checkout-wrapper-right'>
          <div className='cart-container'>
            <div className='cart-wrapper'>
              <Cart />
            </div>
          </div>
        </div>
      </div>

      {/** Modal Select Order Type */}
      <ModalSelectOrderType />

      <Modal
        title='Warning'
        visible={isShowModalCartWarning}
        onOk={handleRemoveCartItems}
        okText="Yes, i'm sure"
        onCancel={handleCancelRemoveCartItems}
      >
        <p>
          {`Are you sure want to change order type into "${tmpOrderType}" ? Menu that unavailable for "${tmpOrderType}" will be removed from your cart.`}
        </p>
        <p>List of unavailable menu:</p>
        {unavailableCartItems && unavailableCartItems.length > 0
          ? unavailableCartItems.map((item, idx) => (
              <p key={item.productId}>
                {idx + 1}. {item.productDetail?.name}
              </p>
            ))
          : false}
      </Modal>
    </AppLayout>
  );
};

export default Checkout;
