import React, { ReactNode, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import FAQItem from '../components/FAQItem';
import HeaderSection from '../components/HeaderSection';
import HowItWorkItem from '../components/HowItWorkItem';
import OurLocation from '../components/OurLocation';
import TopPick from '../components/TopPick';
import AppLayout from '../layout/AppLayout';
import { httpRequest } from '../helpers/api';
import { IHttpResponse, IPayloadPagination } from '../helpers/pagination';
import { generateQueryString } from '../helpers/generateQueryString';
import {
  Button,
  Col,
  Divider,
  Dropdown,
  Input,
  Menu,
  Modal,
  Row,
  Select,
  Space,
  message,
} from 'antd';
import type { MenuProps, SelectProps } from 'antd';
import { IMenu, initialMenu } from '../types/menu.type';
import ModalMenuDetail, { AddToCartItem } from '../components/ModalMenuDetail';
import useAuth from '../hooks/useAuth';
import useModalAuth from '../hooks/useModalAuth';
import useCart, { CartItem } from '../hooks/useCart';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import useGlobalConfig from '../hooks/useGlobalConfig';
import useGlobalFilter from '../hooks/useGlobalFilter';
import CarouselHeaderBanner from '../components/CarouselHeaderBanner';
import { AppConfigProps } from '../types/config.type';
import MenuTabs from '../components/MenuTabs';
import useOrder, { EOrderType } from '../hooks/useOrder';
import { IFilterMenu } from '../components/FilterMenu';
import { useDebounce } from 'use-debounce';
import useGlobalSearch from '../hooks/useGlobalSearch';
import MenuCardItem from '../components/MenuCardItem';
import ContentCheckDeliveryCoverage from '../components/ContentCheckDeliveryCoverage';
import ModalMealPacks from '../components/ModalMealPack';
import ModalMenuDetail2 from '../components/ModalMenuDetail2';
import styled from 'styled-components';
import HeaderSectionMenu from '../components/HeaderSectionMenu';
import _ from 'lodash';
import ModalCheckDeliveryCoverage from '../components/ModalCheckDeliveryCovarage';
import {
  ClockCircleFilled,
  DownOutlined,
  HomeOutlined,
  LockOutlined,
  LogoutOutlined,
  OrderedListOutlined,
  SearchOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { ILocation } from '../types/location.type';
import ModalSelectOrderType from '../components/ModalSelectOrderType';
import AppButton from '../components/AppButton';
import useWindowSize from '../hooks/useWindowSize';

export interface IHow {
  howitworksId: string;
  howitworksImg: string;
  queueOrder: string;
  headline: string;
  subtitle: string;
}

interface IScheduleGroup {
  suburb: string;
  locations: IBaseGroupSchedule[];
}

interface IBaseGroupSchedule extends ILocation {
  scheduleAt: string;
  checked: boolean;
}

export interface IFAQs {
  answer: string;
  question: string;
  faqId: string;
}

const Homepage: React.FC = () => {
  const Title = styled.div`
    font-size: ${({ theme }) => theme.fontSize.title};
    font-weight: ${({ theme }) => theme.fontWeight.bold};
    color: ${({ theme }) => theme.colors.black};
    text-align: center;
    margin-left: 15px;
  `;
  const SelectComponent = styled(Select)``;
  type CustomSelectProps = SelectProps<any> & {
    prefixIcon?: ReactNode;
    styleSearch?: any;
  };
  const SelectWrapper = styled.div`
    position: relative;
    /* margin-right: 10px; */

    .prefix-icon-wrapper {
      position: absolute;
      z-index: 1;
      width: 3rem;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    && .ant-select .ant-select-selector {
      padding-left: calc(3rem - 8px);
      border-radius: 20px;
    }
  `;

  const CustomSelect = ({
    prefixIcon,
    children,
    defaultValue,
    placeholder,
    value,
    styleSearch,
    ...rest
  }: CustomSelectProps) => {
    return (
      <SelectWrapper style={styleSearch}>
        {prefixIcon && (
          <div className='prefix-icon-wrapper' style={{ color: 'red' }}>
            {prefixIcon}
          </div>
        )}
        <SelectComponent
          size='large'
          defaultValue={defaultValue ? defaultValue : undefined}
          {...rest}
          style={rest.style}
          placeholder={placeholder}
          value={value}
        >
          {children}
        </SelectComponent>
      </SelectWrapper>
    );
  };
  const {
    currMenuGroup,
    isShowModalOrderType,
    tmpOrderType,
    orderType,
    isShowModalCheckDeliveryCoverage,
    currSelectedLocation,
    deliveryPostcode,
    currentPostcodes,
    schedules,
    rawSchedules,
    scheduleDeliveries,
    isLoadingSchedules,
    isLoadingScheduleDelivery,
    fetchScheduleDeliveries,
    fetchSchedules,
    setOrderType,
    setDeliveryPostcode,
    setIsShowModalOrderType,
    setTmpOrderType,
    setCurrSelectedLocation,
    setUnavailableSchedule,
    setCurrMenuGroup,
    setMealPackMenu,
    setIsShowModalMealPacks,
    setIsShowModalCheckDeliveryCoverage,
  } = useOrder();
  const {
    setIsShowModalCart,
    setIsShowModalCartWarning,
    addToCart,
    cartInit,
    carts,
    localCarts,
    cartGroup,
    addTmpCartItem,
    removeAllCartItems,
    checkRemoveAllCartItems,
    subTotal,
  } = useCart();
  const { isMobile } = useWindowSize();

  const { init } = useGlobalConfig();

  const history = useHistory();
  let refHowItWorks = React.useRef<any>();
  let refOurLocations = React.useRef<any>();
  const { user, isLoggedIn } = useAuth();
  const { setIsShowModalLogin } = useModalAuth();
  const { clearCart, addMultipleToCart } = useCart();
  const { locations } = useGlobalConfig();

  const { setIsShowModalSelectLocation, setSelectedLocation } =
    useGlobalFilter();

  const location = useLocation();

  const [dataHows, setDataHows] = React.useState<IHow[]>([]);
  const [tmpSelectLocation, setTmpSelectLocation] = React.useState<ILocation>();
  const [isModalMenuVisible, setisModalMenuVisible] =
    React.useState<boolean>(false);
  const [scheduleGroup, setScheduleGroup] = React.useState<IScheduleGroup[]>(
    []
  );
  const [locationData, setLocationData] = React.useState<any[]>([]);
  const [listMenuId, setListMenuId] = React.useState<string[]>([]);

  const [activeMenuTab, setActiveMenuTab] = React.useState<number>(0);
  const [isModalPostCode, setisModalPostCodeVisible] =
    React.useState<boolean>(false);
  const [isCovered, setCovered] = React.useState<boolean>(false);

  const [dataFAQs, setFAQs] = React.useState<IFAQs[]>([]);
  const [menusTopPick, setMenusTopPick] = React.useState<IMenu[]>([]);

  const [isShowModalMenuDetail, setIsShowModalMenuDetail] =
    React.useState(false);
  const [selectedMenu, setSelectedMenu] = React.useState<IMenu>();
  const [selectedMenu2, setSelectedMenu2] = React.useState<IMenu>(initialMenu);

  const handleChooseMenuTab = (date: string) => {
    const activeTab = currMenuGroup.findIndex((tab) => tab.scheduleAt === date);
    setActiveMenuTab(activeTab);
  };
  React.useEffect(() => {
    if (currSelectedLocation) {
      setTmpSelectLocation(currSelectedLocation);
    }
  }, [currSelectedLocation]);

  React.useEffect(() => {
    init();
  }, []);
  const { setGlobalKeyword, globalKeyword } = useGlobalSearch();
  const [searchValue] = useDebounce(globalKeyword, 1000);
  const handleOpenModalOrderType = async (type: EOrderType) => {
    if (type === EOrderType.delivery) {
      setTmpOrderType(type);
    } else if (type === EOrderType.pickup) {
      // setCurrSelectedLocation(undefined);
      setTmpOrderType(type);
    }
  };

  const handleChangePostcode = (e: string) => {
    let unavailableSchedule = [];
    for (const iterator of scheduleDeliveries) {
      const found = iterator.deliveryCoverage?.postalCode[e];
      if (!found) {
        unavailableSchedule.push(
          moment(iterator.scheduleAt).format('MMM DD YYYY')
        );
      }
    }
    if (unavailableSchedule.length > 0) {
      console.log('partial delivery');
      localStorage.setItem('partialDelivery', JSON.stringify(true));
      localStorage.setItem(
        'dataUnavailableSchedule',
        JSON.stringify(unavailableSchedule)
      );
      setUnavailableSchedule(unavailableSchedule);
    } else {
      console.log('not partial delivery');
      localStorage.removeItem('partialDelivery');
      localStorage.removeItem('dataUnavailableSchedule');
      setUnavailableSchedule([]);
    }
    setDeliveryPostcode(e);
    localStorage.setItem('deliveryPostcode', JSON.stringify(e));
  };

  const handleChangeLocation = (e: string) => {
    if (currSelectedLocation?.locationId !== e) {
      const mapScheduleGroup = scheduleGroup.map((item) => ({
        ...item,
        locations: item.locations.map((loc) =>
          loc.locationId === e
            ? { ...loc, checked: !loc.checked }
            : { ...loc, checked: false }
        ),
      }));
      const filter = scheduleGroup.filter(
        (x) => x.locations[0].locationId === e
      );
      const dataLocation: ILocation = {
        ...filter[0].locations[0],
      };
      setCurrSelectedLocation(dataLocation);
      setCurrSelectedLocation(dataLocation);
      localStorage.setItem('location', JSON.stringify(dataLocation));
      localStorage.removeItem('unavailableSchedule');
      setUnavailableSchedule([]);
    }
  };

  const handleMenuClick = (e: string) => {
    if (e === 'delivery') {
      setOrderType(EOrderType.delivery);
      localStorage.setItem('orderType', JSON.stringify(EOrderType.delivery));
      setTmpOrderType(EOrderType.delivery);
      // handleOpenModalOrderType(EOrderType.delivery);
    } else {
      setOrderType(EOrderType.pickup);
      localStorage.setItem('orderType', JSON.stringify(EOrderType.pickup));
      setTmpOrderType(EOrderType.pickup);
      // fetchSchedules();
      // handleOpenModalOrderType(EOrderType.pickup);
    }
  };

  const checkLeftoverCart = () => {
    const curr = localStorage.getItem('dateAddToCart');
    if (curr) {
      let date = JSON.parse(curr);
      if (moment(date).isBefore(moment(), 'day')) {
        removeAllCartItems();
      }
    } else {
      localStorage.setItem(
        'dateAddToCart',
        JSON.stringify(moment().format('YYYY-MM-DD'))
      );
    }
  };
  React.useEffect(() => {
    fetchSchedules();
    fetchScheduleDeliveries();
    checkLeftoverCart();
    window.scrollTo(0, 0);
  }, []);

  React.useEffect(() => {
    if (rawSchedules && rawSchedules.length > 0) {
      const currScheduleGroup = rawSchedules
        .map((item) => ({
          scheduleAt: item.scheduleAt,
          ...item.location,
          checked:
            currSelectedLocation &&
            currSelectedLocation.locationId === item.locationId
              ? true
              : false,
        }))
        .reduce((acc: IScheduleGroup[], curr: IBaseGroupSchedule) => {
          const findIndex = acc.findIndex(
            (item) => item.suburb === curr.suburb
          );

          if (findIndex > -1) {
            const findLocationId = acc[findIndex].locations.findIndex(
              (item) => item.locationId === curr.locationId
            );
            if (findLocationId < 0) {
              acc[findIndex].locations.push(curr);
            }
          } else {
            acc.push({
              suburb: curr.suburb,
              locations: [{ ...curr }],
            });
          }

          return acc;
        }, [] as IScheduleGroup[]);
      const groupedData = currScheduleGroup.reduce(
        (
          acc: { label: string; options: { label: string; value: string }[] }[],
          curr
        ) => {
          const existingGroup = acc.find(
            (group) => group.label === curr.suburb
          );

          if (existingGroup) {
            existingGroup.options.push({
              label: curr.locations[0].locationAddress,
              value: curr.locations[0].locationId,
            });
          } else {
            acc.push({
              label: curr.suburb,
              options: [
                {
                  label: curr.locations[0].locationAddress,
                  value: curr.locations[0].locationId,
                },
              ],
            });
          }

          return acc;
        },
        []
      );
      setLocationData(groupedData);
      setScheduleGroup(_.orderBy(currScheduleGroup, ['suburb', 'asc']));
    }
  }, [rawSchedules]);

  const reMapDataCurrGroup = (
    newFilter?: IFilterMenu,
    searchValue?: string
  ) => {
    if ((newFilter && newFilter.allergens.length > 0) || searchValue) {
      const newMenuGroup = currMenuGroup.map((item) => {
        return {
          ...item,
          category: item.category.map((c) => {
            return {
              ...c,
              menus: c.menus.map((m) => {
                let found;
                if (newFilter || searchValue) {
                  found =
                    m.tags.find((t: any) =>
                      newFilter?.allergens.includes(t.tagId)
                    ) ||
                    m.name.toLowerCase().includes(searchValue?.toLowerCase());
                  if (found) {
                    return {
                      ...m,
                      isHide: false,
                    };
                  } else {
                    return {
                      ...m,
                      isHide: true,
                    };
                  }
                } else {
                  return {
                    ...m,
                    isHide: false,
                  };
                }
              }),
            };
          }),
        };
      });
      setCurrMenuGroup(newMenuGroup);
    } else {
      const newMenuGroup = currMenuGroup.map((item) => {
        return {
          ...item,
          category: item.category.map((c) => {
            return {
              ...c,
              menus: c.menus.map((m) => {
                return {
                  ...m,
                  isHide: false,
                };
              }),
            };
          }),
        };
      });
      setCurrMenuGroup(newMenuGroup);
    }
  };
  const handleMenuOrder = (menu: IMenu) => {
    if (orderType === EOrderType.pickup && !currSelectedLocation) {
      setTmpOrderType(EOrderType.pickup);
      handleOpenModalOrderType(EOrderType.pickup);
    } else if (orderType === EOrderType.delivery && !deliveryPostcode) {
      setTmpOrderType(EOrderType.delivery);
      handleOpenModalOrderType(EOrderType.delivery);
    } else {
      if (menu.itemIds && menu.itemIds.length > 0) {
        setMealPackMenu({
          ...menu,
          items: menu.items
            ? menu.items.map((item) => ({
                ...item,
                currQty: 0,
              }))
            : [],
        });
        setIsShowModalMealPacks(true);
      } else {
        setSelectedMenu2(menu);
        setisModalMenuVisible(true);
      }
    }
  };
  const handleOnClickMenu = (menu: any) => {
    if (orderType === EOrderType.pickup && !currSelectedLocation) {
      setTmpOrderType(EOrderType.pickup);
      handleOpenModalOrderType(EOrderType.pickup);
      setIsShowModalOrderType(true);
    } else if (orderType === EOrderType.delivery && !deliveryPostcode) {
      setTmpOrderType(EOrderType.delivery);
      handleOpenModalOrderType(EOrderType.delivery);
      setIsShowModalOrderType(true);
    } else {
      handleMenuOrder(menu);
    }
  };

  React.useEffect(() => {
    reMapDataCurrGroup(undefined, searchValue);
  }, [searchValue]);

  React.useEffect(() => {
    if (currMenuGroup[activeMenuTab]) {
      const menuId: any[][] = currMenuGroup[activeMenuTab].category.map(
        (group) => group.menus.map((menus) => menus)
      );
      if (menuId.length > 1) {
        const data: any = menuId.map((x) => x.map((y) => y.menuId));
        const newData: any = data.reduce((acc: any, cur: any) => {
          return acc.concat(cur);
        }, []);
        setListMenuId(newData);
      }
    }
  }, [currMenuGroup]);

  const sleep = (ms: number) =>
    new Promise((resolve) => setTimeout(resolve, ms));

  async function scrollTo(id: string) {
    await sleep(1000);
    const violation: any = document.getElementById(id);
    window.scrollTo({
      top: violation.offsetTop - 70,
      behavior: 'smooth',
    });
  }

  React.useEffect(() => {
    if (location.hash && location.hash.includes('#')) {
      const exp = location.hash.split('#');
      if (exp.length > 1 && exp[1]) {
        if (
          exp[1] === 'how-it-works' &&
          refHowItWorks &&
          refHowItWorks.current
        ) {
          scrollTo(exp[1]);
        }
      }
    }
  }, [location, refHowItWorks]);

  React.useEffect(() => {
    const fetchHow = async () => {
      const res = await httpRequest.get<
        IHttpResponse<IPayloadPagination<IHow>>
      >('howitworks');
      setDataHows(res.data.payload.results);
    };
    fetchHow();
  }, []);

  React.useEffect(() => {
    const fetchFAQs = async () => {
      const res = await httpRequest.get<
        IHttpResponse<IPayloadPagination<IFAQs>>
      >(
        'faqs' +
          generateQueryString({
            // limit: 5,
            isImportant: true,
          })
      );
      setFAQs(res.data.payload.results);
    };

    fetchFAQs();
  }, []);

  React.useEffect(() => {
    const fetchMenus = async () => {
      const res = await httpRequest.get<
        IHttpResponse<IPayloadPagination<IMenu>>
      >(
        'menus' +
          generateQueryString({
            limit: 10,
            isTopPick: true,
          })
      );
      setMenusTopPick(
        res.data.payload.results.filter((item) => item.variants.length > 0)
      );
    };
    fetchMenus();
  }, []);

  const handleClickAddToCart2 = (newData: CartItem) => {
    addTmpCartItem(newData);
    setisModalMenuVisible(false);
    message.success(
      `Success add ${newData.quantity} ${newData.productDetail?.name} to your cart`
    );
  };

  const handleClickAddToCart = (newDataCart: AddToCartItem[]) => {
    if (selectedMenu) {
      const list = [];
      for (const item of newDataCart) {
        const addedItem: CartItem = {
          productId: selectedMenu.menuId,
          productVariantId: newDataCart[0].menuVariantId,
          quantity: item.qty,
          buyerId: user.customerId,

          productDetail: {
            ...selectedMenu,
            variant: {} as any,
          },
          meta: {
            pickupFromLocationId: item.location?.locationId || '',
            pickupScheduledAt: moment(item.date).format('YYYY-MM-DD') || '',
            pickupTime: {
              startAt: item.availabilityPickupTime?.startAt,
              endAt: item.availabilityPickupTime?.endAt,
            },
            deliveryScheduleAt: '',
          },
        };
        list.push(addedItem);
      }
      addMultipleToCart(list, 'add', () => {
        history.push('/menu');
      });
    }
  };

  function handleButton() {
    const url = 'https://pay.airwallex.com/hkgo8zhb3f4t';
    // code 1
    // const a = document.createElement('a');
    // a.setAttribute('href', url);
    // a.setAttribute('target', '_blank');
    // a.click();

    // code 2
    setTimeout(() => {
      window.open(url, '_blank');
    }, 1000);
  }

  return (
    <AppLayout isHomepage>
      {/* <div id='home' /> */}
      <CarouselHeaderBanner />
      {currMenuGroup.length > 0 && (
        <div className='homepage-section'>
          {/* <Title>What do you want to eat today?</Title> */}
          <HeaderSection
            title='What do you want to eat today?'
            subtitle='Real Meals, Real Easy: Delivering Deliciousness to Your Table'
          />
          <div className='search-bar'>
            <div
              style={{
                display: 'grid',
                gridTemplateColumns: isMobile ? '150px 1fr' : '200px 1fr',
                gap: 12,
                padding: '0 20px',
                // marginLeft: 'auto',
                // marginRight: 'auto',
                // width: isMobile ? '80%' : '100%',
              }}
            >
              <CustomSelect
                prefixIcon={<img src='/images/clock.svg' />}
                defaultValue={orderType}
                onChange={handleMenuClick}
                style={{ width: '100%' }}
              >
                <Select.Option key={'delivery'} value={'delivery'}>
                  Delivery
                </Select.Option>
                <Select.Option key={'pickup'} value={'pickup'}>
                  Pickup
                </Select.Option>
              </CustomSelect>
              <CustomSelect
                showSearch
                styleSearch={{ width: '100%' }}
                prefixIcon={<img src='/images/map-marker.svg' />}
                defaultValue={
                  orderType == EOrderType.delivery
                    ? deliveryPostcode
                    : currSelectedLocation?.locationAddress
                }
                size='large'
                className='home-select'
                options={
                  orderType == EOrderType.delivery
                    ? currentPostcodes
                    : locationData
                }
                filterOption={(input, option) => {
                  return orderType == EOrderType.delivery
                    ? (option?.value ?? ('' as any))
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    : (option?.label ?? ('' as any))
                        .toLowerCase()
                        .includes(input.toLowerCase());
                }}
                style={{ width: '100%' }}
                // width={"100%"}
                suffixIcon={null}
                onChange={
                  orderType == EOrderType.delivery
                    ? handleChangePostcode
                    : handleChangeLocation
                }
                placeholder={
                  orderType == EOrderType.delivery
                    ? 'Search postcode'
                    : 'Search location'
                }
              ></CustomSelect>
            </div>
          </div>
          {/* </div> */}
          {currMenuGroup.length > 0 && (
            <MenuTabs
              activeTabIdx={activeMenuTab}
              dates={currMenuGroup || []}
              onCLick={(date: string) => handleChooseMenuTab(date)}
              showModalMenu={isModalMenuVisible}
            />
          )}
          {currMenuGroup.length > 0 &&
            currMenuGroup[activeMenuTab].category.map((group, idx) =>
              group.menus.filter((m) => m.isHide).length ===
              group.menus.length ? (
                false
              ) : (
                <>
                  {group.categoryName === 'Meal' ? (
                    <div key={idx} className='menu-group-items'>
                      {group.menus
                        .slice(0, 8)
                        .map((menu) =>
                          menu.isHide ? (
                            false
                          ) : (
                            <MenuCardItem
                              selectedScheduleAt={
                                currMenuGroup.length > 0
                                  ? currMenuGroup[activeMenuTab].scheduleAt
                                  : ''
                              }
                              key={menu.menuId}
                              menu={menu}
                              handleOnClickMenu={(data) =>
                                handleOnClickMenu(data)
                              }
                            />
                          )
                        )}
                    </div>
                  ) : (
                    false
                  )}
                </>
              )
            )}
          <Link to='menu' style={{ display: 'flex' }}>
            <AppButton
              size={'middle'}
              style={{
                borderRadius: 6,
                backgroundColor: '#FFEFEF',
                height: '38px',
                fontSize: '12px',
                fontWeight: 'bold',
                marginRight: 'auto',
                marginLeft: 'auto',
                marginTop: '20px',
              }}
            >
              <div style={{ fontWeight: 'bolder', color: '#FF4A50' }}>
                {'See All Menu'}
              </div>
            </AppButton>
          </Link>
        </div>
      )}

      {menusTopPick.length > 0 && listMenuId.length > 0 && (
        <TopPick
          data={menusTopPick.map((menu) => ({
            menuId: menu.menuId,
            title: menu.name,
            description:
              menu.description.length > 100
                ? menu.description.substring(0, 98) + '...'
                : menu.description,
            price: String(
              menu.variants
                .find((vari) => vari.isDefaultPrice)
                ?.price.toFixed(2)
            ),
            image: menu.images.length > 0 ? menu.images[0].imageUrl || '' : '',
            onClick: (menuId: string) => {
              setSelectedMenu(
                menusTopPick.find((item) => item.menuId === menuId)
              );
              handleOnClickMenu(
                menusTopPick.find((item) => item.menuId === menuId)
              );
              // setIsShowModalMenuDetail(true);
            },
            variants: menu.variants,
            schedule: currMenuGroup[activeMenuTab].scheduleAt
              ? currMenuGroup[activeMenuTab].scheduleAt
              : '',
            isAvailable:
              listMenuId.includes(menu.menuId) &&
              currMenuGroup[activeMenuTab].scheduleAt
                ? true
                : false,
          }))}
          locations={locations}
        />
      )}

      {isShowModalMenuDetail && selectedMenu ? (
        <ModalMenuDetail
          page='top-pick'
          data={selectedMenu}
          onCancel={() => {
            setIsShowModalMenuDetail(false);
          }}
          onAddMultipleToCart={(listData) => {
            if (isLoggedIn) {
              if (listData[0].location?.locationId) {
                Modal.confirm({
                  title: 'Confirmation',
                  content:
                    'Are you sure want to change pickup location? Your existing cart will be removed.',
                  onOk: () => {
                    clearCart();
                    setSelectedLocation(listData[0].location as any);
                    setIsShowModalSelectLocation(false);
                    handleClickAddToCart(listData);
                  },
                  okText: `Yes, I'm sure`,
                  okType: 'primary',
                });
              } else {
                handleClickAddToCart(listData);
                setIsShowModalMenuDetail(false);
              }
            } else {
              setIsShowModalMenuDetail(false);
              setIsShowModalLogin(true);
            }
          }}
        />
      ) : (
        false
      )}

      {/** Modal Meal Packs */}
      <ModalMealPacks
        scheduleAt={
          currMenuGroup && currMenuGroup.length > 0
            ? currMenuGroup[activeMenuTab].scheduleAt
            : ''
        }
      />
      {/** Modal Select Order Type */}
      <ModalSelectOrderType />

      {isModalMenuVisible && (
        <ModalMenuDetail2
          selectedScheduleAt={currMenuGroup[activeMenuTab].scheduleAt || ''}
          data={selectedMenu2}
          onCancel={() => {
            setisModalMenuVisible(false);
          }}
          onAddToCart={(newData) => {
            handleClickAddToCart2(newData);
          }}
        />
      )}

      <div
        ref={refHowItWorks}
        id='how-it-works'
        className='homepage-section-full'
      >
        <div className='homepage-section'>
          <HeaderSection title='How it Works' subtitle='' />
          <div className='how-container'>
            {dataHows.length > 0 &&
              dataHows.map((item, idx) => (
                <HowItWorkItem
                  key={idx}
                  title={item.headline}
                  description={item.subtitle}
                  index={idx + 1}
                  image={item.howitworksImg}
                />
              ))}
          </div>
        </div>
      </div>

      <div className='homepage-section'>
        <HeaderSection title='Frequently Asked Questions' subtitle='' />
        <div className='faq-container'>
          <FAQItem data={dataFAQs} />
        </div>
        <div className='faqs-link-container'>
          <Link to='faqs' className='faqs-link'>
            See All FAQs
          </Link>
        </div>
      </div>

      <div className='section-cta'>
        <div className='bottom-banner-wrapper'>
          <h1>Ready to try our meals?</h1>
          <Link to='menu' className='bottom-banner-link'>
            <img alt='menu-icon' src='./images/tudung.svg' />
            <span>Yes Please!</span>
          </Link>
        </div>
      </div>
    </AppLayout>
  );
};

export default Homepage;
