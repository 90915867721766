import _, { findIndex } from 'lodash';
import moment from 'moment';
import { useEffect } from 'react';
import { createGlobalState } from 'react-hooks-global-state';
import { httpRequest } from '../helpers/api';
import { generateQueryString } from '../helpers/generateQueryString';
import {
  AppConfigProps,
  BaseResponsePaginationProps,
} from '../types/config.type';
import { ILocation, initialLocation } from '../types/location.type';
import { IMenu, initialMenu } from '../types/menu.type';
import {
  ISchedule,
  IScheduleDelivery,
  IScheduleDeliveryMenu,
} from '../types/schedules.type';
import useCart from './useCart';
import useGlobalFilter from './useGlobalFilter';

export enum EOrderType {
  pickup = 'Pickup',
  delivery = 'Delivery',
}

export type CategoryMenuGroup = {
  categoryId: string;
  order: number;
  categoryName: string;
  menus: any[];
};

export type MenuGroup = {
  scheduleAt: string;
  category: CategoryMenuGroup[];
  isUnavailable?: boolean;
};

export type DataOrderCreateProps = {
  NavigateURL?: string;
  email?: string;
  locationName?: string;
  locationAddress?: string;
  locationContact?: string;
  totalPrice: string | number;
  paymentGroupedId: string;
};

type State = {
  isShowModalOrderType: boolean;
  isShowModalCheckDeliveryCoverage: boolean;
  isShowModalMealPacks: boolean;
  orderType: EOrderType | undefined;
  currentPostcodes: { value: string }[];
  isLoadingScheduleDelivery: boolean;
  scheduleDeliveries: IScheduleDelivery[];
  isLoadingSchedules: boolean;
  rawSchedules: ISchedule[];
  schedules: ISchedule[];
  currMenuGroup: MenuGroup[];
  tmpMenuGroupPickup: MenuGroup[];
  tmpMenuGroupDelivery: MenuGroup[];
  tmpOrderType: EOrderType | undefined;
  currSelectedLocation: ILocation | undefined;
  mealPackMenu: IMenu;
  checkoutAsGuest: boolean;
  deliveryAddress: string;
  deliveryPostcode: string;
  dataForOrderCreatedModal: DataOrderCreateProps;
  isShowModalOrderCreated: boolean;
  dataPromo?: AppConfigProps;
  partialDelivery: boolean;
  unavailableSchedule: string[];
};

const initialState: State = {
  orderType: EOrderType.delivery,
  isShowModalOrderType: false,
  isShowModalCheckDeliveryCoverage: false,
  isShowModalMealPacks: false,
  currentPostcodes: [],
  isLoadingScheduleDelivery: false,
  scheduleDeliveries: [],
  isLoadingSchedules: false,
  schedules: [],
  rawSchedules: [],
  currMenuGroup: [],
  tmpMenuGroupPickup: [],
  tmpMenuGroupDelivery: [],
  tmpOrderType: undefined,
  currSelectedLocation: undefined,
  mealPackMenu: { ...initialMenu },
  checkoutAsGuest: false,
  deliveryAddress: '',
  deliveryPostcode: '',
  dataForOrderCreatedModal: {
    NavigateURL: '',
    email: '',
    locationName: '',
    locationAddress: '',
    locationContact: '',
    totalPrice: 0,
    paymentGroupedId: '',
  },
  isShowModalOrderCreated: false,
  dataPromo: undefined,
  partialDelivery: false,
  unavailableSchedule: [],
};
const { useGlobalState } = createGlobalState(initialState);

const currentDate = moment().startOf('day');

export default function useOrder() {
  const { selectedGlobalDate } = useGlobalFilter();
  const [orderType, setOrderType] = useGlobalState('orderType');
  const [isShowModalOrderType, setIsShowModalOrderType] = useGlobalState(
    'isShowModalOrderType'
  );
  const [tmpOrderType, setTmpOrderType] = useGlobalState('tmpOrderType');
  const [
    isShowModalCheckDeliveryCoverage,
    setIsShowModalCheckDeliveryCoverage,
  ] = useGlobalState('isShowModalCheckDeliveryCoverage');
  const [isShowModalMealPacks, setIsShowModalMealPacks] = useGlobalState(
    'isShowModalMealPacks'
  );
  const [mealPackMenu, setMealPackMenu] = useGlobalState('mealPackMenu');

  const [currentPostcodes, setCurrentPostcodes] =
    useGlobalState('currentPostcodes');

  const [isLoadingScheduleDelivery, setIsLoadingScheduleDelivery] =
    useGlobalState('isLoadingScheduleDelivery');

  const [scheduleDeliveries, setScheduleDeliveries] =
    useGlobalState('scheduleDeliveries');

  const [isLoadingSchedules, setIsLoadingSchedules] =
    useGlobalState('isLoadingSchedules');

  const [schedules, setSchedules] = useGlobalState('schedules');

  const [rawSchedules, setRawSchedules] = useGlobalState('rawSchedules');

  const [currMenuGroup, setCurrMenuGroup] = useGlobalState('currMenuGroup');

  const [tmpMenuGroupPickup, setTmpMenuGroupPickup] =
    useGlobalState('tmpMenuGroupPickup');

  const [tmpMenuGroupDelivery, setTmpMenuGroupDelivery] = useGlobalState(
    'tmpMenuGroupDelivery'
  );

  const [currSelectedLocation, setCurrSelectedLocation] = useGlobalState(
    'currSelectedLocation'
  );

  const [checkoutAsGuest, setCheckoutAsGuest] =
    useGlobalState('checkoutAsGuest');

  const [deliveryAddress, setDeliveryAddress] =
    useGlobalState('deliveryAddress');

  const [deliveryPostcode, setDeliveryPostcode] =
    useGlobalState('deliveryPostcode');

  const [dataForOrderCreatedModal, setDataForOrderCreatedModal] =
    useGlobalState('dataForOrderCreatedModal');

  const [isShowModalOrderCreated, setIsShowModalOrderCreated] = useGlobalState(
    'isShowModalOrderCreated'
  );

  const [dataPromo, setDataPromo] = useGlobalState('dataPromo');

  const [partialDelivery, setPartialDelivery] =
    useGlobalState('partialDelivery');

  const [unavailableSchedule, setUnavailableSchedule] = useGlobalState(
    'unavailableSchedule'
  );

  const dateFrom = moment().isBefore(moment().hours(10).minutes(5))
    ? moment().add(1, 'day').format('YYYY-MM-DD').toString()
    : moment().add(2, 'day').format('YYYY-MM-DD').toString();

  const dateUntil = moment().isBefore(moment().hours(10).minutes(5))
    ? moment().add(6, 'day').format('YYYY-MM-DD').toString()
    : moment().add(8, 'day').format('YYYY-MM-DD').toString();

  const fetchScheduleDeliveries = async () => {
    setIsLoadingScheduleDelivery(true);
    try {
      const res = await httpRequest.get<
        BaseResponsePaginationProps<IScheduleDelivery>
      >(
        'schedule-deliveries' +
          generateQueryString({
            scheduleFrom: dateFrom,
            scheduleUntil: dateUntil,
            customRange: true,
          })
      );

      if (res && res.data.payload && res.data.payload.results) {
        setScheduleDeliveries(res.data.payload.results);

        const tmpPostcodes: { value: string }[] = [];

        for (const item of res.data.payload.results) {
          if (item.deliveryCoverage) {
            for (const postcode in item.deliveryCoverage?.postalCode) {
              tmpPostcodes.push({ value: postcode });
            }
          }
        }
        setCurrentPostcodes(_.uniqBy(tmpPostcodes, 'value'));
        mapScheduleDelivery(res.data.payload.results);
      }
    } catch (error) {
      console.error('failed fetch schedule deliveries, ', error);
    } finally {
      setIsLoadingScheduleDelivery(false);
    }
  };

  const fetchSchedules = async (locationId?: string | null) => {
    setIsLoadingSchedules(true);
    // console.log('fetch schedules 1');
    try {
      const res = await httpRequest.get<BaseResponsePaginationProps<ISchedule>>(
        'schedules' +
          generateQueryString({
            dateFrom,
            dateUntil,
            locationId,
            customRange: true,
          })
      );

      if (res && res.data.payload && res.data.payload.results) {
        setSchedules(res.data.payload.results);
        mapSchedulePickup(res.data.payload.results);
        if (!locationId) {
          setRawSchedules(res.data.payload.results);
        }
      }
    } catch (error) {
      console.error('failed fetch schedules, ', error);
    } finally {
      setIsLoadingSchedules(false);
    }
  };

  const mapSchedulePickup = (currentSchedules: ISchedule[]) => {
    const newData = currentSchedules
      .reduce((acc: MenuGroup[], curr: ISchedule) => {
        const idx = acc.findIndex(
          (item) => item.scheduleAt === curr.scheduleAt
        );

        if (idx > -1) {
          const categoryIdx = acc[idx].category.findIndex(
            (item) => item.categoryId === curr.menu.categoryId
          );

          if (categoryIdx > -1) {
            acc[idx].category[categoryIdx] = {
              categoryId: curr.menu.categoryId!,
              order: curr.menu.category?.order || 1,
              categoryName: curr.menu.category!.categoryName,
              menus: _.uniqBy(
                [...acc[idx].category[categoryIdx].menus!, ...[curr.menu]],
                'menuId'
              ),
            };
          } else {
            acc[idx].category.push({
              categoryId: curr.menu.categoryId!,
              order: curr.menu.category?.order || 1,
              categoryName: curr.menu.category!.categoryName,
              menus: [{ ...curr.menu }],
            });
          }
        } else {
          acc.push({
            scheduleAt: curr.scheduleAt,
            category: [
              {
                categoryId: curr.menu.categoryId!,
                order: curr.menu.category?.order || 1,
                categoryName: curr.menu.category!.categoryName,
                menus: [{ ...curr.menu }],
              },
            ],
          });
        }

        return acc;
      }, [] as MenuGroup[])
      .map((item) => ({
        ...item,
        category: _.sortBy(item.category, 'order'),
      }));

    setTmpMenuGroupPickup(_.sortBy(newData, 'scheduleAt'));
  };

  const mapScheduleDelivery = (
    currentScheduleDeliveries: IScheduleDelivery[]
  ) => {
    const currScheduleGroup = [];

    for (const schedule of currentScheduleDeliveries) {
      currScheduleGroup.push({
        date: schedule.scheduleAt,
        id: schedule.scheduleAt,
      });
    }

    const newData = currentScheduleDeliveries.map((item) => {
      const category = item.schedule_delivery_menus!.reduce(
        (acc: CategoryMenuGroup[], curr: IScheduleDeliveryMenu) => {
          const idx = acc.findIndex(
            (i: any) => i.categoryId === curr.menu!.categoryId
          );

          if (idx > -1) {
            acc[idx] = {
              categoryId: curr.menu!.categoryId!,
              order: curr.menu!.category!.order!,
              categoryName: curr.menu?.category?.categoryName!,
              menus: _.uniqBy([...acc[idx].menus, ...[curr.menu]], 'menuId'),
            };
          } else {
            acc.push({
              categoryId: curr.menu!.categoryId!,
              order: curr.menu!.category!.order!,
              categoryName: curr.menu!.category!.categoryName,
              menus: [{ ...curr.menu }],
            });
          }

          return acc;
        },
        [] as CategoryMenuGroup[]
      );

      return {
        scheduleAt: item.scheduleAt,
        category: _.sortBy(category, 'order'),
      };
    });

    setTmpMenuGroupDelivery(_.sortBy(newData, 'scheduleAt'));
  };

  const checkDeliveryCoverage = (value: string) => {
    const found = currentPostcodes.find((postcode) => postcode.value === value);

    if (found) {
      return true;
    }
    return false;
  };
  const setLocalStorage = () => {
    localStorage.setItem('orderType', JSON.stringify('Delivery'));
    return localStorage.getItem('orderType');
  };
  const localOrderType = localStorage.getItem('orderType')
    ? localStorage.getItem('orderType')
    : setLocalStorage();
  const localCurrPickupLocation = localStorage.getItem('location');
  const currDeliveryAddress = localStorage.getItem('deliveryAddress');
  const currDeliveryPostcode = localStorage.getItem('deliveryPostcode');
  const isPartialDelivery = localStorage.getItem('partialDelivery');
  const dataUnavailableSchedule = localStorage.getItem('unavailableSchedule');

  useEffect(() => {
    if (dataUnavailableSchedule) {
      setUnavailableSchedule(JSON.parse(dataUnavailableSchedule));
    }
  }, [dataUnavailableSchedule]);

  useEffect(() => {
    if (isPartialDelivery) {
      setPartialDelivery(JSON.parse(isPartialDelivery));
    }
  }, [isPartialDelivery]);

  useEffect(() => {
    if (localOrderType) {
      setOrderType(JSON.parse(localOrderType));
    }
  }, [localOrderType]);

  useEffect(() => {
    if (localCurrPickupLocation) {
      // console.log('fetch schedules 3');
      setCurrSelectedLocation(JSON.parse(localCurrPickupLocation));
    }
  }, [localCurrPickupLocation]);

  useEffect(() => {
    if (currDeliveryAddress) {
      setDeliveryAddress(JSON.parse(currDeliveryAddress));
    }
  }, [currDeliveryAddress]);

  useEffect(() => {
    if (currDeliveryPostcode) {
      setDeliveryPostcode(JSON.parse(currDeliveryPostcode));
    }
  }, [currDeliveryPostcode]);

  useEffect(() => {
    if (orderType === EOrderType.pickup && tmpMenuGroupPickup.length > 0) {
      setCurrMenuGroup(tmpMenuGroupPickup);
    } else if (
      orderType === EOrderType.delivery &&
      tmpMenuGroupDelivery.length > 0
    ) {
      setCurrMenuGroup(tmpMenuGroupDelivery);
    } else {
      setCurrMenuGroup(tmpMenuGroupPickup);
    }
  }, [orderType, tmpMenuGroupDelivery, tmpMenuGroupPickup]);

  return {
    isShowModalOrderType,
    orderType,
    isShowModalCheckDeliveryCoverage,
    currentPostcodes,
    isLoadingScheduleDelivery,
    scheduleDeliveries,
    isLoadingSchedules,
    schedules,
    rawSchedules,
    currMenuGroup,
    tmpOrderType,
    currSelectedLocation,
    isShowModalMealPacks,
    mealPackMenu,
    checkoutAsGuest,
    deliveryAddress,
    deliveryPostcode,
    dateFrom,
    dateUntil,
    dataForOrderCreatedModal,
    isShowModalOrderCreated,
    dataPromo,
    partialDelivery,
    unavailableSchedule,

    setIsShowModalOrderType,
    setIsShowModalMealPacks,
    setOrderType,
    setIsShowModalCheckDeliveryCoverage,
    checkDeliveryCoverage,
    fetchScheduleDeliveries,
    fetchSchedules,
    setTmpOrderType,
    setCurrSelectedLocation,
    setMealPackMenu,
    setCheckoutAsGuest,
    setDeliveryAddress,
    setDeliveryPostcode,
    setCurrMenuGroup,
    setDataForOrderCreatedModal,
    setIsShowModalOrderCreated,
    setDataPromo,
    setPartialDelivery,
    setUnavailableSchedule,
  };
}
