import { createGlobalState } from 'react-hooks-global-state';
import { httpRequest } from '../helpers/api';
import { IVariant } from '../types/menu.type';
import { message } from 'antd';
import { getErrorMessage } from '../helpers/errorHandler';
import { IHttpResponse, IPayloadPagination } from '../helpers/pagination';
import { ILocation } from '../types/location.type';
import { useCallback, useEffect, useMemo } from 'react';
import useOrder, { EOrderType } from './useOrder';
import _ from 'lodash';
import { BaseResponsePaginationProps } from '../types/config.type';
import { ISchedule } from '../types/schedules.type';
import { generateQueryString } from '../helpers/generateQueryString';
import useAuth from './useAuth';
import { CheckCustomerDiscountProps } from '../components/Cart';
import { CheckCustomerDiscount } from '../types/customer.type';
import { FetchAllAppConfigResponse } from '../types/appConfig.type';

export type CartItem = {
  cartId?: string;
  productId: string;
  productVariantId?: string;
  quantity: number;
  buyerId?: string;
  productDetail?: {
    name: string;
    variant: IVariant;
    variants: IVariant[];
    images: Array<{
      fileId: string;
      imageUrl: string | null;
    }>;
  };
  meta: {
    pickupFromLocationId: string;
    pickupFromLocationDetail?: ILocation;
    pickupScheduledAt: string;
    pickupTime: {
      startAt?: string;
      endAt?: string;
    };
    deliveryScheduleAt: string;
    menuItems?: {
      menuId: string;
      menuName: string;
      qty: number;
      images: any[];
    }[];
  };
};

export type CartGroup = {
  scheduleAt: string;
  orderType: EOrderType;
  cartItems: CartItem[];
  subTotalCartGroup: number;
  deliveryFeeCartGroup: any;
  isFreeDelivery: boolean;
};

type CartProps = {
  isLoading: boolean;
  carts: CartItem[];
  localCarts: CartItem[] | undefined;
  isShowModalCart: boolean;
  unavailableCartItems: CartItem[];
  isShowModalCartWarning: boolean;
  cartGroup: CartGroup[];
  freeDeliveryRule: number;
  minimumOrderRule: number;
  deliveryFees: { scheduleAt: string; fee: number }[];
  loadingConstructCart: boolean;
  transactionDeliveryFee: number;
  transactionSubTotal: number;
  metaDiscountCartGroup: {
    totalDiscount: number;
    currentDiscounts: {
      scheduleAt: string;
      discount: {
        discountName: string;
        discountNominal: number;
        meta: any;
      };
    }[];
  };
};

const initialState: CartProps = {
  isLoading: false,
  carts: [],
  localCarts: undefined,
  isShowModalCart: false,
  unavailableCartItems: [],
  isShowModalCartWarning: false,
  cartGroup: [],
  freeDeliveryRule: 0,
  minimumOrderRule: 0,
  deliveryFees: [],
  loadingConstructCart: false,
  transactionDeliveryFee: 0,
  transactionSubTotal: 0,
  metaDiscountCartGroup: {
    totalDiscount: 0,
    currentDiscounts: [],
  },
};
const { useGlobalState } = createGlobalState(initialState);

export default function useCart() {
  const [isLoading, setIsLoading] = useGlobalState('isLoading');
  const [carts, setCarts] = useGlobalState('carts');
  const [isShowModalCart, setIsShowModalCart] =
    useGlobalState('isShowModalCart');
  const [localCarts, setLocalCarts] = useGlobalState('localCarts');
  const [unavailableCartItems, setUnavailableCartItems] = useGlobalState(
    'unavailableCartItems'
  );
  const [isShowModalCartWarning, setIsShowModalCartWarning] = useGlobalState(
    'isShowModalCartWarning'
  );
  const [cartGroup, setCartGroup] = useGlobalState('cartGroup');
  const { isLoggedIn } = useAuth();

  const {
    orderType,
    schedules,
    scheduleDeliveries,
    currSelectedLocation,
    tmpOrderType,
    deliveryPostcode,
  } = useOrder();

  const addToCart = async (value: CartItem, how: 'add' | 'replace' = 'add') => {
    setIsLoading(true);
    try {
      let tmpCart = [...carts];
      const variantCart = tmpCart.find(
        (item) =>
          item.productVariantId === value.productVariantId &&
          item.meta?.pickupFromLocationId ===
            value.meta?.pickupFromLocationId &&
          item.meta?.pickupScheduledAt === value.meta?.pickupScheduledAt &&
          item.meta?.pickupTime.startAt === value.meta?.pickupTime.startAt &&
          item.meta?.pickupTime.endAt === value.meta?.pickupTime.endAt
      );

      if (variantCart) {
        tmpCart.forEach((item) => {
          if (
            item.productVariantId === value.productVariantId &&
            item.meta?.pickupFromLocationId ===
              value.meta?.pickupFromLocationId &&
            item.meta?.pickupScheduledAt === value.meta?.pickupScheduledAt &&
            item.meta?.pickupTime.startAt === value.meta?.pickupTime.startAt &&
            item.meta?.pickupTime.endAt === value.meta?.pickupTime.endAt
          ) {
            item.quantity =
              how === 'replace'
                ? value.quantity
                : item.quantity + value.quantity;
          }
        });

        if (how === 'replace' && variantCart.quantity === 0) {
          tmpCart = tmpCart.filter(
            (item) =>
              !(
                item.productVariantId === variantCart.productVariantId &&
                item.meta?.pickupFromLocationId ===
                  variantCart.meta?.pickupFromLocationId &&
                item.meta?.pickupScheduledAt ===
                  variantCart.meta?.pickupScheduledAt &&
                item.meta?.pickupTime.startAt ===
                  variantCart.meta?.pickupTime.startAt &&
                item.meta?.pickupTime.endAt ===
                  variantCart.meta?.pickupTime.endAt
              )
          );
        }
        await httpRequest.post('/carts', variantCart);
      } else {
        tmpCart.push(value);
        await httpRequest.post('/carts', value);
      }

      cartInit();
      setIsLoading(false);

      message.info('Food Added to Cart');
    } catch (err) {
      setIsLoading(false);
      message.error('Failed to update cart. ' + getErrorMessage(err));
    }
  };

  const addMultipleToCart = async (
    values: CartItem[],
    how: 'add' | 'replace' = 'add',
    callbackOnSuccess: Function
  ) => {
    setIsLoading(true);
    try {
      const tmpCart = [...carts];

      let promises = [];
      for (const value of values) {
        const variantCart = tmpCart.find(
          (item) =>
            item.productVariantId === value.productVariantId &&
            item.meta?.pickupFromLocationId ===
              value.meta?.pickupFromLocationId &&
            item.meta?.pickupScheduledAt === value.meta?.pickupScheduledAt &&
            item.meta?.pickupTime.startAt === value.meta?.pickupTime.startAt &&
            item.meta?.pickupTime.endAt === value.meta?.pickupTime.endAt
        );

        if (variantCart) {
          tmpCart.forEach((item) => {
            if (
              item.productVariantId === value.productVariantId &&
              item.meta?.pickupFromLocationId ===
                value.meta?.pickupFromLocationId &&
              item.meta?.pickupScheduledAt === value.meta?.pickupScheduledAt &&
              item.meta?.pickupTime.startAt ===
                value.meta?.pickupTime.startAt &&
              item.meta?.pickupTime.endAt === value.meta?.pickupTime.endAt
            ) {
              item.quantity =
                how === 'replace'
                  ? value.quantity
                  : item.quantity + value.quantity;
            }
          });

          promises.push(httpRequest.post('/carts', variantCart));
        } else {
          tmpCart.push(value);
          promises.push(httpRequest.post('/carts', value));
        }
      }

      await Promise.all(promises);
      if (how === 'replace') {
        message.success('Success update product to cart');
      } else {
        message.success('Success add product to cart');
      }
      setCarts(tmpCart);

      if (callbackOnSuccess) {
        callbackOnSuccess();
      }
      setIsLoading(false);

      // message.info('Food Added to Cart');
    } catch (err) {
      setIsLoading(false);
      message.error('Failed to update cart. ' + getErrorMessage(err));
    }
  };

  const cartInit = async () => {
    try {
      const resCart = await httpRequest.get<
        IHttpResponse<IPayloadPagination<CartItem>>
      >(`/carts`);
      setCarts(resCart.data.payload.results);
    } catch (err) {
      message.error('Failed to get cart data. ' + getErrorMessage(err));
    }
  };

  const clearCart = async () => {
    try {
      await httpRequest.delete<IHttpResponse<IPayloadPagination<CartItem>>>(
        `/carts`
      );
      setCarts([]);
    } catch (err) {
      message.error('Failed to clear cart. ', +getErrorMessage(err));
    }
  };

  const removeProductVariantFromCart = async (
    productId: string,
    productVariantId: string
  ) => {
    const newCart = carts.filter(
      (item) => item.productVariantId !== productVariantId
    );
    await httpRequest.delete(`/carts/${productId}/${productVariantId}`);
    setCarts(newCart);
  };

  const removeProductVariantByPickupScheduleFromCart = async (
    productId: string,
    productVariantId: string,
    pickupScheduledAt: string,
    pickupTimeStart: string,
    pickupTimeEnd: string
  ) => {
    await httpRequest.delete(
      `/carts/${productId}/${productVariantId}/${pickupScheduledAt}/${pickupTimeStart}/${pickupTimeEnd}`
    );
    cartInit();
  };

  const updateVariant = async (cartId: string, newVariantId: string) => {
    setIsLoading(true);
    try {
      await httpRequest.patch('/carts/' + cartId + '/variant', {
        productVariantId: newVariantId,
      });
      setIsLoading(false);
      cartInit();
    } catch (err) {
      message.error('Failed update variant. ' + getErrorMessage(err));
    }
  };

  const addTmpCartItem = (cartItem: CartItem) => {
    const tmpLocalCarts = localStorage.getItem('carts');
    let newLocalCarts: CartItem[] = [];

    if (!tmpLocalCarts) {
      newLocalCarts.push(cartItem);
    } else if (
      localCarts &&
      cartItem.meta.menuItems &&
      cartItem.meta.menuItems.length > 0
    ) {
      newLocalCarts = [...localCarts, ...[cartItem]];
    } else {
      let parse = JSON.parse(tmpLocalCarts);
      parse = parse.map((item: CartItem) => {
        let currCartScheduleAt = '';
        let cartItemScheduleAt = '';
        if (orderType === EOrderType.pickup) {
          currCartScheduleAt = item.meta.pickupScheduledAt;
          cartItemScheduleAt = cartItem.meta.pickupScheduledAt;
        } else if (orderType === EOrderType.delivery) {
          currCartScheduleAt = item.meta.deliveryScheduleAt;
          cartItemScheduleAt = cartItem.meta.deliveryScheduleAt;
        }
        if (
          item.productVariantId === cartItem.productVariantId &&
          cartItemScheduleAt === currCartScheduleAt
        ) {
          return {
            ...item,
            quantity: item.quantity + 1,
          };
        } else {
          return { ...item };
        }
      });
      const found = parse.find((item: CartItem) => {
        let currCartScheduleAt = '';
        let cartItemScheduleAt = '';
        if (orderType === EOrderType.pickup) {
          currCartScheduleAt = item.meta.pickupScheduledAt;
          cartItemScheduleAt = cartItem.meta.pickupScheduledAt;
        } else if (orderType === EOrderType.delivery) {
          currCartScheduleAt = item.meta.deliveryScheduleAt;
          cartItemScheduleAt = cartItem.meta.deliveryScheduleAt;
        }
        return (
          item.productVariantId === cartItem.productVariantId &&
          cartItemScheduleAt === currCartScheduleAt
        );
      });
      if (!found) {
        newLocalCarts = [...parse, ...[cartItem]];
      } else {
        newLocalCarts = [...parse];
      }
    }

    localStorage.setItem('carts', JSON.stringify(newLocalCarts));
    setLocalCarts(newLocalCarts);
  };

  const removeTmpCartItem = (cartItem: CartItem) => {
    let newCarts = [];
    if (localCarts) {
      newCarts = localCarts.filter(
        (item) => item.productId !== cartItem.productId && item
      );

      localStorage.setItem('carts', JSON.stringify(newCarts));
      setLocalCarts(newCarts);
    }
  };

  const removeTmpCartQtyItem = (cartItem: CartItem) => {
    let newCarts = [];
    if (localCarts) {
      newCarts = localCarts.map((item) => {
        if (item.productVariantId === cartItem.productVariantId) {
          return {
            ...item,
            quantity: item.quantity - 1,
          };
        } else {
          return { ...item };
        }
      });
      localStorage.setItem('carts', JSON.stringify(newCarts));
      setLocalCarts(newCarts);
    }
  };

  const addTmpCartQtyItem = (cartItem: CartItem) => {
    let newCarts = [];
    if (localCarts) {
      newCarts = localCarts.map((item) => {
        if (item.productVariantId === cartItem.productVariantId) {
          return {
            ...item,
            quantity: item.quantity + 1,
          };
        } else {
          return { ...item };
        }
      });
      localStorage.setItem('carts', JSON.stringify(newCarts));
      setLocalCarts(newCarts);
    }
  };

  const checkRemoveAllCartItems = () => {
    if (localCarts) {
      setUnavailableCartItems(_.uniqBy(localCarts, 'productId'));
      return localCarts?.length;
    } else {
      return 0;
    }
  };

  const removeAllCartItems = () => {
    localStorage.removeItem('carts');
    setLocalCarts(undefined);
  };

  const checkCartItems = async (
    newOrderType: EOrderType,
    isSwitchPickupLocation?: boolean
  ) => {
    // console.log("newOrderType", newOrderType);
    // console.log("localCarts", localCarts);
    // console.log("schedules", schedules);
    // console.log("scheduleDeliveries", scheduleDeliveries);

    let unavailableItems: CartItem[] = [];
    if (localCarts) {
      if (newOrderType === EOrderType.delivery) {
        for (const cartItem of localCarts) {
          for (const scheduleDelivery of scheduleDeliveries) {
            if (scheduleDelivery.schedule_delivery_menus) {
              const found = scheduleDelivery.schedule_delivery_menus.find(
                (item) => item.menuId === cartItem.productId
              );
              if (!found) {
                unavailableItems.push(cartItem);
              }
            }
          }
        }
      } else if (newOrderType === EOrderType.pickup) {
        for (const cartItem of localCarts) {
          console.log('useCart ::: schedules', schedules);

          const foundScheduleAt = schedules.find(
            (item) =>
              item.scheduleAt === cartItem.meta?.deliveryScheduleAt &&
              item.menuId === cartItem.productId
          );
          console.log('useCart ::: foundScheduleAt', foundScheduleAt);
          let foundDifferentLocation: ISchedule[] = [];
          // if (currSelectedLocation && isSwitchPickupLocation) {
          //   const res = await httpRequest.get<
          //     BaseResponsePaginationProps<ISchedule>
          //   >(
          //     "schedules" +
          //       generateQueryString({
          //         dateFrom,
          //         dateUntil,
          //       })
          //   );
          //   foundDifferentLocation = res.data.payload.results.filter(
          //     (item) =>
          //       cartItem.productId === item.menuId &&
          //       currSelectedLocation.locationId === item.locationId &&
          //       item
          //   );
          //   console.log("foundDifferentLocation", foundDifferentLocation);
          // }

          if (!foundScheduleAt) {
            unavailableItems.push(cartItem);
          }
        }
      }

      setUnavailableCartItems(_.uniqBy(unavailableItems, 'productId'));
    }

    return unavailableItems.length;
  };

  const resetUnavailableCartItems = () => {
    setUnavailableCartItems([]);
  };

  const removeCartItems = (isSwitch?: boolean) => {
    if (localCarts) {
      const newLocalCarts = localCarts?.filter((cart) => {
        const found = unavailableCartItems.find(
          (item) => cart.productId === item.productId
        );
        if (!found) {
          return cart;
        }
      });
      // .map((item) => ({
      //   ...item,
      //   meta: {
      //     pickupFromLocationId:
      //       tmpOrderType === EOrderType.delivery
      //         ? ""
      //         : currSelectedLocation
      //         ? currSelectedLocation?.locationId
      //         : "",
      //     pickupScheduledAt:
      //       tmpOrderType === EOrderType.delivery
      //         ? ""
      //         : item.meta.deliveryScheduleAt,
      //     pickupTime: {
      //       startAt:
      //         tmpOrderType === EOrderType.delivery
      //           ? ""
      //           : currSelectedLocation?.timeframe.split("-") &&
      //             currSelectedLocation?.timeframe.split("-").length > 0
      //           ? currSelectedLocation?.timeframe.split("-")[0]
      //           : currSelectedLocation?.timeframe,
      //       endAt:
      //         tmpOrderType === EOrderType.delivery
      //           ? ""
      //           : currSelectedLocation?.timeframe.split("-") &&
      //             currSelectedLocation?.timeframe.split("-").length > 0
      //           ? currSelectedLocation?.timeframe.split("-")[1]
      //           : currSelectedLocation?.timeframe,
      //     },
      //     deliveryScheduleAt:
      //       tmpOrderType === EOrderType.delivery
      //         ? item.meta.pickupScheduledAt
      //         : item.meta.deliveryScheduleAt,
      //   },
      // }));

      console.log('useCart ::: newLocalCarts', newLocalCarts);

      if (newLocalCarts.length > 0) {
        localStorage.setItem('carts', JSON.stringify(newLocalCarts));
        setLocalCarts(newLocalCarts);
      } else {
        setLocalCarts(undefined);
      }
    }
  };

  const deliveryFee = (scheduleAt: string) => {
    let fee = 0;
    if (orderType === EOrderType.delivery) {
      if (scheduleDeliveries && deliveryPostcode) {
        const found = scheduleDeliveries.find(
          (item) => item.scheduleAt === scheduleAt
        );
        if (found) {
          for (const key in found.deliveryCoverage?.postalCode) {
            if (key === deliveryPostcode) {
              fee = found.deliveryCoverage?.postalCode[key];
            }
          }
        }
      }
    }

    return fee;
  };

  const discountAmount = () => {
    return 0;
  };

  useEffect(() => {
    const currLocalCarts = localStorage.getItem('carts');

    if (currLocalCarts) {
      const parse = JSON.parse(currLocalCarts);
      parse.length > 0 ? setLocalCarts(parse) : setLocalCarts(undefined);
    }
  }, []);

  const [freeDeliveryRule, setFreeDeliveryRule] =
    useGlobalState('freeDeliveryRule');

  const [minimumOrderRule, setMinimumOrderRule] =
    useGlobalState('minimumOrderRule');

  const [loadingConstructCart, setLoadingConstructCart] = useGlobalState(
    'loadingConstructCart'
  );

  const [transactionDeliveryFee, setTransactionDeliveryFee] = useGlobalState(
    'transactionDeliveryFee'
  );

  const [transactionSubTotal, setTransactionSubTotal] = useGlobalState(
    'transactionSubTotal'
  );

  const [metaDiscountCartGroup, setMetaDiscountCartGroup] = useGlobalState(
    'metaDiscountCartGroup'
  );

  // [localCarts, freeDeliveryRule, scheduleDeliveries]

  // useEffect(() => {
  //   if (localCarts && localCarts.length) {
  const constructCart = async () => {
    try {
      if (localCarts && localCarts.length) {
        setLoadingConstructCart(true);
        let transactionSubTotal = 0;
        let transactionDeliveryFee = 0;
        const newCarts = localCarts.reduce(
          (acc: CartGroup[], curr: CartItem) => {
            let scheduleAt = '';
            if (curr.meta?.pickupScheduledAt) {
              scheduleAt = curr.meta?.pickupScheduledAt;
            } else if (curr.meta?.deliveryScheduleAt) {
              scheduleAt = curr.meta?.deliveryScheduleAt;
            }
            const foundIndex = acc.findIndex(
              (i) => i.scheduleAt === scheduleAt
            );
            if (foundIndex > -1) {
              acc[foundIndex].cartItems.push({ ...curr });
            } else {
              acc.push({
                scheduleAt: scheduleAt,
                orderType: EOrderType.pickup,
                cartItems: [{ ...curr }],
                subTotalCartGroup: 0,
                deliveryFeeCartGroup: 0,
                isFreeDelivery: false,
              });
            }
            return acc;
          },
          []
        );

        const newCarts2: CartGroup[] = [];

        for (let i = 0; i < newCarts.length; i++) {
          const cartGroup = newCarts[i];

          // Calculate subtotal each group
          const subTotalCartGroup = cartGroup.cartItems.reduce(
            (acc: number, curr: CartItem) =>
              acc > 0
                ? (acc =
                    acc +
                    curr.productDetail?.variant.price! *
                      (curr.meta.menuItems && curr.meta.menuItems.length > 0
                        ? 1
                        : curr.quantity))
                : (acc =
                    curr.productDetail?.variant.price! *
                    (curr.meta.menuItems && curr.meta.menuItems.length > 0
                      ? 1
                      : curr.quantity)),
            0
          );
          transactionSubTotal += subTotalCartGroup;

          // Calculate delivery fee each group
          let currentFreeDeliveryRule = 0;
          const res = await httpRequest.get<FetchAllAppConfigResponse>(
            'app-configs?keys=MINIMUM_ORDER,FREE_DELIVERY'
          );
          if (res.data.payload.results) {
            res.data.payload.results.forEach((item) => {
              if (item.key === 'MINIMUM_ORDER') {
                setMinimumOrderRule(
                  parseInt(item.value) ? parseInt(item.value) : 0
                );
              }
              if (item.key === 'FREE_DELIVERY') {
                setFreeDeliveryRule(
                  parseInt(item.value) ? parseInt(item.value) : 0
                );
                currentFreeDeliveryRule = parseInt(item.value);
              }
            });
          }
          let deliveryFeeCartGroup = 0;
          const found = scheduleDeliveries.find(
            (item) => item.scheduleAt === cartGroup.scheduleAt
          );
          // Validation if customer get free delivery or not
          console.log(
            'useCart ::: constructCart. newCart1: ',
            cartGroup.scheduleAt,
            subTotalCartGroup,
            currentFreeDeliveryRule,
            found,
            scheduleDeliveries
          );
          if (subTotalCartGroup >= currentFreeDeliveryRule) {
            deliveryFeeCartGroup = 0;
          } else if (found) {
            deliveryFeeCartGroup += found.deliveryCoverage
              ? Number(found.deliveryCoverage.postalCode[deliveryPostcode])
              : 0;
          } else {
            console.log('useCart ::: constructCart. newCart2: something wrong');
          }
          transactionDeliveryFee += deliveryFeeCartGroup;

          newCarts2.push({
            ...cartGroup,
            subTotalCartGroup,
            deliveryFeeCartGroup,
            isFreeDelivery:
              subTotalCartGroup >= freeDeliveryRule ? true : false,
          });
        }

        console.log('useCart ::: constructCart. newCarts3: ', newCarts2);
        setCartGroup(newCarts2);
        if (isLoggedIn) {
          await constructCartGroupDiscount(newCarts2);
        }
        setTransactionSubTotal(transactionSubTotal);
        setTransactionDeliveryFee(transactionDeliveryFee);
      }
    } catch (error) {
      console.error('useCart ::: constructCart. error: ', error);
    } finally {
      setLoadingConstructCart(false);
    }
  };

  const constructCartGroupDiscount = async (carts: CartGroup[]) => {
    try {
      setLoadingConstructCart(true);
      let currentDiscounts: any[] = [];
      let totalDiscount: number = 0;
      if (carts && carts.length) {
        for (const cart of carts) {
          // Calculate discount each group
          console.log(
            'useCart ::: constructCartGroupDiscount. cart.subTotalCartGroup: ',
            cart.subTotalCartGroup
          );
          console.log(
            'useCart ::: constructCartGroupDiscount. cart.deliveryFeeCartGroup: ',
            cart.deliveryFeeCartGroup
          );
          const totalPrice = cart.subTotalCartGroup + cart.deliveryFeeCartGroup;
          const resDiscount =
            await httpRequest.post<CheckCustomerDiscountProps>(
              '/transactions/check/new-customer/discount',
              {
                totalPrice,
              }
            );
          if (resDiscount && resDiscount.data.payload) {
            const payload = resDiscount.data.payload;

            currentDiscounts.push({
              scheduleAt: cart.scheduleAt,
              discount: {
                discountNominal: payload.currentHighestDiscount.discountNominal,
                discountName: payload.currentHighestDiscount.discountName,
                meta: payload,
              },
            });

            totalDiscount += payload.currentHighestDiscount.discountNominal;
          }
        }
      }
      console.log(
        'useCart ::: constructCartGroupDiscount. metaDiscountCartGroup: ',
        {
          totalDiscount,
          currentDiscounts,
        }
      );
      setMetaDiscountCartGroup({
        totalDiscount,
        currentDiscounts,
      });
    } catch (error) {
      console.error('useCart ::: constructCartGroupDiscount. error: ', error);
    } finally {
      setLoadingConstructCart(false);
    }
  };

  const totalPay = useMemo(() => {
    console.log(
      'useCart ::: totalPay. transactionSubTotal:',
      transactionSubTotal
    );
    console.log(
      'useCart ::: totalPay. transactionDeliveryFee:',
      transactionDeliveryFee
    );
    console.log(
      'useCart ::: totalPay. metaDiscountCartGroup:',
      metaDiscountCartGroup
    );
    return (
      transactionSubTotal +
      transactionDeliveryFee -
      (metaDiscountCartGroup && metaDiscountCartGroup.totalDiscount > 0
        ? metaDiscountCartGroup.totalDiscount
        : 0)
    );
  }, [metaDiscountCartGroup, transactionDeliveryFee, transactionSubTotal]);

  const isAllowToCheckout = useMemo(() => {
    const founds = cartGroup.filter(
      (item) => item.subTotalCartGroup < minimumOrderRule
    );

    if (founds && founds.length) {
      return {
        allow: false,
        dataGroup: founds,
      };
    } else {
      return {
        allow: true,
        dataGroup: undefined,
      };
    }
  }, [transactionSubTotal, minimumOrderRule, cartGroup]);

  return {
    constructCart,
    setMinimumOrderRule,
    setFreeDeliveryRule,
    isAllowToCheckout,
    minimumOrderRule,
    freeDeliveryRule,
    metaDiscountCartGroup,
    transactionSubTotal,
    transactionDeliveryFee,
    loadingConstructCart,
    // totalPayRaw: totalPayPlusDiscount,
    // checkMyDiscount,
    isLoading,
    carts,
    locationIdInCart:
      carts.length > 0 ? carts[0].meta?.pickupFromLocationId : undefined,
    isShowModalCart,
    localCarts,
    unavailableCartItems,
    isShowModalCartWarning,
    cartGroup,
    totalPay,

    subTotal: 0, // => unused
    deliveryFee,
    discountAmount,
    addToCart,
    addMultipleToCart,
    cartInit,
    clearCart,
    removeProductVariantFromCart,
    removeProductVariantByPickupScheduleFromCart,
    updateVariant,
    setIsShowModalCart,
    addTmpCartItem,
    removeTmpCartItem,
    removeTmpCartQtyItem,
    addTmpCartQtyItem,
    setLocalCarts,
    setUnavailableCartItems,
    checkCartItems,
    resetUnavailableCartItems,
    setIsShowModalCartWarning,
    removeCartItems,
    setCartGroup,
    checkRemoveAllCartItems,
    removeAllCartItems,
  };
}
