import { Checkbox, Col, Input, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import useAuth from '../hooks/useAuth';
import useOrder from '../hooks/useOrder';
import FormInputCustom from './FormInputCustom';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import { Link } from 'react-router-dom';
import AppButton from './AppButton';
import useCart from '../hooks/useCart';

type Props = {
  isLoading: boolean;
  onCheckout: (dataGuest?: DataCheckoutGuest) => void;
};

export type DataCheckoutGuest = {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
};

const onLoad = (marker: any) => {};

const CheckoutPickup: React.FC<Props> = (props) => {
  const { checkoutAsGuest, currSelectedLocation, setIsShowModalOrderType } =
    useOrder();
  const { localCarts, loadingConstructCart, isAllowToCheckout } = useCart();
  const { isLoggedIn, user } = useAuth();
  const [isAgree, setIsAgree] = useState<boolean>(false);
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [phoneNumber, setPhoneNumber] = useState<string>('');

  const isDisabled = () => {
    if (!isAgree) {
      return true;
    }

    if (!isAllowToCheckout.allow) {
      return true;
    }

    if (checkoutAsGuest) {
      if (!firstName || !lastName || !email || !phoneNumber) {
        return true;
      }
    }

    if (!localCarts || localCarts.length === 0) {
      return true;
    }

    return false;
  };

  const handleChangePickupLocation = () => {
    setIsShowModalOrderType(true);
  };

  useEffect(() => {
    if (isLoggedIn && user) {
      const firstName =
        user.fullName.split(' ').length > 0 ? user.fullName.split(' ')[0] : '';
      const lastName =
        user.fullName.split(' ').length > 1 ? user.fullName.split(' ')[1] : '';
      setFirstName(firstName);
      setLastName(lastName);
      setEmail(user.email);
      setPhoneNumber(user.phoneNumber);
    }
  }, [isLoggedIn, user]);

  return (
    <div>
      {checkoutAsGuest && (
        <div style={{ width: '100%' }}>
          <div className='checkout-content-header'>
            <h3>Customer Information</h3>
          </div>
          <Row gutter={10}>
            <Col span={12}>
              <FormInputCustom label='First name'>
                <Input
                  size='large'
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                />
              </FormInputCustom>
            </Col>
            <Col span={12}>
              <FormInputCustom label='Last name'>
                <Input
                  size='large'
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                />
              </FormInputCustom>
            </Col>
          </Row>

          <Row gutter={10}>
            <Col span={12}>
              <FormInputCustom label='Phone Number'>
                <Input
                  style={{ width: '100%' }}
                  size='large'
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                />
              </FormInputCustom>
            </Col>
            <Col span={12}>
              <FormInputCustom label='Email'>
                <Input
                  style={{ width: '100%' }}
                  size='large'
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </FormInputCustom>
            </Col>
          </Row>
        </div>
      )}

      <div className='checkout-content-header'>
        <h3>Pickup Location</h3>
        {/* <span onClick={handleChangePickupLocation}>Change Pickup Location</span> */}
      </div>

      <div className='checkout-info-container'>
        {currSelectedLocation && (
          <>
            <LoadScript
              googleMapsApiKey={process.env.REACT_APP_MAP_API_KEY as string}
            >
              <GoogleMap
                id='checkout-map'
                center={{
                  lat:
                    (currSelectedLocation.locationGeoloc?.lat as number) ||
                    -3.745,
                  lng:
                    (currSelectedLocation.locationGeoloc?.lng as number) ||
                    -38.523,
                }}
                zoom={9}
              >
                <Marker
                  onLoad={onLoad}
                  position={{
                    lat:
                      (currSelectedLocation.locationGeoloc?.lat as number) ||
                      -3.745,
                    lng:
                      (currSelectedLocation.locationGeoloc?.lng as number) ||
                      -38.523,
                  }}
                />
              </GoogleMap>
            </LoadScript>

            <h4>{currSelectedLocation.locationName}</h4>
            <p>
              {currSelectedLocation.locationAddress},{' '}
              {currSelectedLocation.city}, {currSelectedLocation.suburb},{' '}
              {currSelectedLocation.postalCode}, {currSelectedLocation.state}
            </p>
            <div className='timeframe'>
              <p>Pickup timeframe</p>
              <span />
              <p>{currSelectedLocation.timeframe}</p>
            </div>
          </>
        )}
      </div>

      <Checkbox
        checked={isAgree}
        className='checkout-agreement'
        onChange={(e) => setIsAgree(e.target.checked)}
      >
        I agree to the{' '}
        <Link target='_blank' to='/terms-and-conditions'>
          Terms and conditions
        </Link>{' '}
        &{' '}
        <Link target='_blank' to='/privacy-policy'>
          Privacy Policy
        </Link>
      </Checkbox>

      <AppButton
        loading={props.isLoading || loadingConstructCart}
        onClick={() => {
          if (firstName && lastName && phoneNumber && email) {
            props.onCheckout({
              firstName,
              lastName,
              email,
              phoneNumber,
            });
          }
        }}
        block
        disabled={isDisabled()}
        type='primary'
        style={{ marginTop: 20, marginBottom: 10 }}
      >
        Pay Now
      </AppButton>
    </div>
  );
};

export default CheckoutPickup;
