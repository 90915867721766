import {
  Autocomplete,
  GoogleMap,
  LoadScript,
  Marker,
} from '@react-google-maps/api';
import {
  setKey,
  setDefaults,
  setLanguage,
  setRegion,
  fromAddress,
  fromLatLng,
  fromPlaceId,
  setLocationType,
  geocode,
  RequestType,
} from 'react-geocode';
import {
  AutoComplete,
  Checkbox,
  Col,
  Input,
  Modal,
  Radio,
  RadioChangeEvent,
  Row,
  Select,
  Space,
  Tag,
} from 'antd';
import _ from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { httpRequest } from '../helpers/api';
import { generateQueryString } from '../helpers/generateQueryString';
import { enumerateDaysBetweenDates } from '../helpers/order';
import useAuth from '../hooks/useAuth';
import useCart from '../hooks/useCart';
import useOrder, { EOrderType } from '../hooks/useOrder';
import { CustomerAddressProperties } from '../types/address.type';
import {
  AppConfigProps,
  BaseResponsePaginationProps,
} from '../types/config.type';
import { IScheduleDelivery } from '../types/schedules.type';
import AppButton from './AppButton';
import FormInputCustom from './FormInputCustom';
import { IHttpResponse, IPayloadPagination } from '../helpers/pagination';
import { FetchAllAppConfigResponse } from '../types/appConfig.type';

const { TextArea } = Input;

type DataCustomerProps = {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  address: string;
  addressNote: string;
  deliveryInformation?: string;
};

export type StateProps = { stateId: string; stateName: string };

export type PostcodeProps = {
  postcode: string;
  state: string;
  suburbName: string;
};

type DeliveryTimeframeProps = {
  id: number;
  scheduleAt: string;
  timeframes: TimeframesProps[];
  selectedTimeframe: any;
};

export interface DataCheckoutDelivery extends DataCustomerProps {
  state: string;
  postcode: string;
  suburb: string;
  deliveryTimeframes: DeliveryTimeframeProps[];
  coords: { lat: number; lng: number };
}

type TimeframesProps = {
  dateFormat: string;
  dateRaw: string;
};

type Props = {
  isLoading: boolean;
  onCheckout: (dataGuest?: DataCheckoutDelivery) => void;
};

const onLoadMarker = (marker: any) => {};

const CheckoutDelivery: React.FC<Props> = (props) => {
  const {
    checkoutAsGuest,
    scheduleDeliveries,
    orderType,
    deliveryAddress,
    deliveryPostcode,
  } = useOrder();
  const { localCarts, isAllowToCheckout, loadingConstructCart } = useCart();
  const { isLoggedIn, user } = useAuth();
  const [dataCustomer, setDataCustomer] = useState<DataCustomerProps>({
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    address: '',
    addressNote: '',
  });
  const [states, setStates] = useState<StateProps[]>([]);
  const [postcodes, setPostcodes] = useState<PostcodeProps[]>([]);
  const [suburbs, setSuburb] = useState<PostcodeProps[]>([]);
  const [tmpSuburbs, setTmpSuburb] = useState<PostcodeProps[]>([]);

  const [currPostcode, setCurrPostcode] = useState<string>('');
  const [currSuburb, setCurrSuburb] = useState<string>('');
  const [currState, setCurrState] = useState<string>('');

  const [isAgree, setIsAgree] = useState<boolean>(false);
  const [primaryAddress, setPrimaryAddress] =
    useState<CustomerAddressProperties>();
  const [minimumOrder, setMinimumOrder] = useState<number>(0);
  const [subTotalPrice, setSubTotalPrice] = useState<number>(0);
  const [freeDelivery, setFreeDelivery] = useState<number>(0);
  const [myAddresses, setMyAddresses] = useState<CustomerAddressProperties[]>(
    []
  );
  const [showModalAddresses, setShowModalAddresses] = useState<boolean>(false);

  const [deliveryTimeframes, setDeliveryTimeframes] = useState<
    DeliveryTimeframeProps[]
  >([]);

  const isDisabled = () => {
    if (!isAgree || !coords) {
      return true;
    }

    if (!isAllowToCheckout.allow) {
      return true;
    }

    if (checkoutAsGuest) {
      if (
        !dataCustomer.firstName ||
        !dataCustomer.lastName ||
        !dataCustomer.email ||
        !dataCustomer.phoneNumber ||
        !dataCustomer.address
        // !currPostcode ||
        // !currState ||
        // !currSuburb
      ) {
        return true;
      }
    }

    const isDeliveryTimeframeEmpty = deliveryTimeframes.find(
      (item) => !item.selectedTimeframe
    );

    if (isDeliveryTimeframeEmpty) {
      return true;
    }

    if (!localCarts || localCarts.length === 0) {
      return true;
    }

    return false;
  };

  const handleChangePostcode = (value: string) => {
    setCurrPostcode(value);

    setCurrSuburb('');
    const postcode = postcodes.find((item) => item.postcode === value);
    if (postcode) {
      const currSuburbs = tmpSuburbs.filter((item) => item.postcode === value);
      setSuburb(currSuburbs);
    }
  };

  const handleChangeSuburb = (value: string) => {
    setCurrSuburb(value);
    const suburb = suburbs.find((item) => item.suburbName === value);
    if (suburb && states.length > 0) {
      const currState = states.find((item) => item.stateId === suburb.state);
      setCurrState(currState?.stateName || '');
    }
  };

  const fetchSuburbByPostcode = async (postcodeNumbers: string[]) => {
    const res = await httpRequest.get<{ payload: PostcodeProps[] }>(
      'postcodes' +
        generateQueryString({
          postcodeNumbers,
        })
    );
    if (res && res.data && res.data.payload && res.data.payload.length > 0) {
      setTmpSuburb(res.data.payload);
    }
  };

  const fetchStates = async () => {
    const res = await httpRequest.get<{ payload: StateProps[] }>(
      'postcodes/get-all-state'
    );
    if (res && res.data && res.data.payload && res.data.payload.length > 0) {
      setStates(res.data.payload);
    }
  };

  const handleChooseDeliveryTimeFrame = (data: {
    scheduleAt: string;
    time: string;
  }) => {
    const newDeliveryTimeFrames = deliveryTimeframes.map((item) => {
      if (item.scheduleAt === data.scheduleAt) {
        return {
          ...item,
          selectedTimeframe: data.time,
        };
      } else {
        return { ...item };
      }
    });
    setDeliveryTimeframes(newDeliveryTimeFrames);
  };

  const handlePickMyAddress = () => {
    setShowModalAddresses(true);
  };

  enum OutputFormat {
    XML = 'xml', // Output format XML
    JSON = 'json', // Output format JSON
  }
  useEffect(() => {
    fetchStates();
    setDefaults({
      key: process.env.REACT_APP_MAP_API_KEY as string, // Your API key here.
      language: 'en', // Default language for responses.
      region: 'es', // Default region for responses.
      outputFormat: OutputFormat.JSON,
    });
  }, []);

  useEffect(() => {
    if (postcodes.length > 0) {
      fetchSuburbByPostcode(postcodes.map((item) => item.postcode));
    }
  }, [postcodes]);

  useEffect(() => {
    if (scheduleDeliveries && scheduleDeliveries.length > 0) {
      let currPostcodes = [];
      for (const postcode in scheduleDeliveries[0].deliveryCoverage
        ?.postalCode) {
        currPostcodes.push({
          postcode,
          state: postcode,
          suburbName: postcode,
        });
      }
      setPostcodes(currPostcodes);

      const deliveryTimeframes: DeliveryTimeframeProps[] = [];
      const currScheduleDeliveries: IScheduleDelivery[] = [];

      if (localCarts) {
        for (const local of localCarts) {
          const found = scheduleDeliveries.find(
            (item) =>
              item.scheduleAt ===
              (orderType === EOrderType.delivery
                ? local.meta.deliveryScheduleAt
                : local.meta.pickupScheduledAt)
          );
          if (found) {
            currScheduleDeliveries.push(found);
          }
        }
      }

      for (let idx = 0; idx < currScheduleDeliveries.length; idx++) {
        const schedule = currScheduleDeliveries[idx];
        const resDates: TimeframesProps[] = enumerateDaysBetweenDates(
          schedule.deliveryRangeStartAt,
          schedule.deliveryRangeEndAt
        );
        deliveryTimeframes.push({
          id: idx,
          scheduleAt: schedule.scheduleAt,
          timeframes: resDates,
          selectedTimeframe: '',
        });
      }
      console.log('deliveryTimeframes', deliveryTimeframes);
      setDeliveryTimeframes(_.uniqBy(deliveryTimeframes, 'scheduleAt'));
    }
  }, [scheduleDeliveries]);

  useEffect(() => {
    if (deliveryAddress) {
      setDataCustomer({ ...dataCustomer, address: deliveryAddress });
    }
  }, [deliveryAddress]);

  useEffect(() => {
    if (deliveryPostcode && tmpSuburbs.length > 0) {
      handleChangePostcode(deliveryPostcode);
    }
  }, [deliveryPostcode, tmpSuburbs]);

  async function fetchMyAddress() {
    const res = await httpRequest.get<
      BaseResponsePaginationProps<CustomerAddressProperties>
    >('customer-addresses');
    if (res.data && res.data.payload && res.data.payload.results.length > 0) {
      setMyAddresses(res.data.payload.results);
    }
  }

  const handleSelectAddress = (address: CustomerAddressProperties) => {
    console.log(address);
    setPrimaryAddress(address);
    setCoords(address.geoLoc as any);
    setDataCustomer({
      ...dataCustomer,
      address: address.address || '',
      addressNote: address.note || '',
    });
    setShowModalAddresses(false);
  };

  useEffect(() => {
    if (primaryAddress) {
      handleChangePostcode(primaryAddress.postCode);
      setCurrSuburb(primaryAddress.suburb);
      const stateFound = states.find(
        (item) => item.stateId === primaryAddress.state
      );
      if (stateFound) {
        setCurrState(stateFound.stateName);
      }
    }
  }, [primaryAddress, states]);

  useEffect(() => {
    if (isLoggedIn && user) {
      const firstName =
        user.fullName.split(' ').length > 0 ? user.fullName.split(' ')[0] : '';
      const lastName =
        user.fullName.split(' ').length > 1 ? user.fullName.split(' ')[1] : '';
      setDataCustomer({
        ...dataCustomer,
        firstName,
        lastName,
        email: user.email,
        phoneNumber: user.phoneNumber,
      });

      fetchMyAddress();
    }
  }, [isLoggedIn, user]);

  const [autocomplete, setAutocomplete] = useState<any>();
  const [map, setMap] = useState<any>();
  const [coords, setCoords] = useState<{
    lat: number;
    lng: number;
  }>();

  const onLoad = (a: any) => {
    setAutocomplete(a);
  };

  const onPlaceChanged = () => {
    if (autocomplete !== null) {
      const place = autocomplete.getPlace();
      setCoords({
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
      });
      // console.log("place", place);
      for (const addressComp of place.address_components) {
        for (const dataType of addressComp.types) {
          if (dataType === 'postal_code') {
            setCurrPostcode(addressComp.long_name);
          } else if (dataType === 'locality') {
            setCurrSuburb(addressComp.long_name);
          } else if (dataType === 'administrative_area_level_1') {
            setCurrState(addressComp.short_name);
          }
        }
      }
      setDataCustomer((val) => ({ ...val, address: place.formatted_address }));
    } else {
      console.log('Autocomplete is not loaded yet!');
    }
  };

  const handleData = (e: any) => {
    console.log('ee', e);
  };

  const handleClickMap = (e: any) => {
    setCoords({ lat: e.latLng.lat(), lng: e.latLng.lng() });
    map.setCenter({ lat: e.latLng.lat(), lng: e.latLng.lng() });

    fromLatLng(e.latLng.lat(), e.latLng.lng())
      .then(({ results }) => {
        const { lat, lng } = results[0].geometry.location;
        console.log(results);

        for (const addressComp of results[0].address_components) {
          for (const dataType of addressComp.types) {
            if (dataType === 'postal_code') {
              setCurrPostcode(addressComp.long_name);
            } else if (dataType === 'locality') {
              setCurrSuburb(addressComp.long_name);
            } else if (dataType === 'administrative_area_level_1') {
              setCurrState(addressComp.short_name);
            }
          }
        }
        setDataCustomer({
          ...dataCustomer,
          address: results[0].formatted_address,
        });
        setStates(results[0].address_components);
      })
      .catch(console.error);
  };

  return (
    <div>
      <div className='checkout-content-header'>
        <h3>Address Information</h3>
        {isLoggedIn && (
          <span onClick={() => handlePickMyAddress()}>
            Pick from My Address
          </span>
        )}
      </div>

      <div>
        <Row gutter={10}>
          <Col span={12}>
            <FormInputCustom label='First name' required>
              <Input
                placeholder='Enter your first name'
                size='large'
                value={dataCustomer.firstName}
                onChange={(e) =>
                  setDataCustomer({
                    ...dataCustomer,
                    firstName: e.target.value,
                  })
                }
              />
            </FormInputCustom>
          </Col>
          <Col span={12}>
            <FormInputCustom label='Last name' required>
              <Input
                size='large'
                placeholder='Enter your last name'
                value={dataCustomer.lastName}
                onChange={(e) =>
                  setDataCustomer({ ...dataCustomer, lastName: e.target.value })
                }
              />
            </FormInputCustom>
          </Col>
        </Row>
        {/* <Row gutter={10}>
          <Col span={12}>
            <FormInputCustom label='Postcode' required>
              <Select
                showSearch
                value={currPostcode}
                disabled={postcodes.length === 0}
                size='large'
                options={postcodes.map((item) => ({
                  label: item.postcode,
                  value: item.postcode,
                }))}
                filterOption={(input, option) =>
                  (option?.label ?? ("" as any))
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                style={{ width: "100%" }}
                onChange={handleChangePostcode}
                placeholder='Search postcode'
              />
            </FormInputCustom>
          </Col>
          <Col span={12}>
            <FormInputCustom label='Suburb' required>
              <Select
                showSearch
                disabled={postcodes.length === 0 || !currPostcode}
                size='large'
                value={currSuburb}
                options={suburbs.map((item) => ({
                  label: item.suburbName,
                  value: item.suburbName,
                }))}
                filterOption={(input, option) =>
                  (option?.label ?? ("" as any))
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                style={{ width: "100%" }}
                onChange={handleChangeSuburb}
                placeholder='Select suburb'
              />
            </FormInputCustom>
          </Col>
        </Row>

        <Row gutter={10}>
          <Col span={24}>
            <FormInputCustom label='State' required>
              <Input
                value={currState || ""}
                defaultValue={currState || ""}
                disabled
                style={{ width: "100%" }}
                size='large'
                placeholder='Please select your suburb'
              />
            </FormInputCustom>
          </Col>
        </Row> */}
        <Row gutter={10}>
          <Col span={24}>
            <FormInputCustom label='Address' required>
              <div className='checkout-info-container'>
                <LoadScript
                  libraries={['places']}
                  region='AU'
                  googleMapsApiKey={process.env.REACT_APP_MAP_API_KEY as string}
                >
                  <GoogleMap
                    id='checkout-map-delivery'
                    mapContainerStyle={{
                      height: 300,
                      width: '100%',
                    }}
                    center={
                      coords || {
                        lat: -33.86945338595781,
                        lng: 151.16476129339597,
                      }
                    }
                    zoom={15}
                    options={{
                      // streetViewControl: false,
                      disableDefaultUI: true,
                    }}
                    onLoad={(map) => setMap(map)}
                    onClick={handleClickMap}
                  >
                    <Autocomplete
                      onLoad={onLoad}
                      onPlaceChanged={onPlaceChanged}
                      restrictions={{ country: 'AU' }}
                    >
                      <input
                        type='text'
                        placeholder='Enter your delivery address...'
                        style={{
                          boxSizing: `border-box`,
                          border: `1px solid transparent`,
                          width: `95%`,
                          height: `42px`,
                          padding: `0 12px`,
                          borderRadius: `3px`,
                          boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
                          fontSize: `14px`,
                          outline: `none`,
                          textOverflow: `ellipses`,
                          position: 'absolute',
                          left: '0',
                          right: '0',
                          top: '10px',
                          marginLeft: 'auto',
                          marginRight: 'auto',
                        }}
                      />
                      {/* setDataCustomer({ ...dataCustomer, address: e.target.value }) */}
                    </Autocomplete>
                    {coords && coords.lat && coords.lng && (
                      <Marker
                        onDragEnd={handleData}
                        position={coords}
                        onLoad={onLoadMarker}
                      />
                    )}
                  </GoogleMap>
                </LoadScript>
              </div>
              <div>
                Search your address and set your precise location by click it on
                map
              </div>
            </FormInputCustom>
          </Col>
        </Row>

        <Row gutter={10}>
          <Col span={24}>
            <FormInputCustom label='Phone Number' required>
              <Input
                style={{ width: '100%' }}
                size='large'
                placeholder='Enter your phone number'
                value={dataCustomer.phoneNumber}
                onChange={(e) =>
                  setDataCustomer({
                    ...dataCustomer,
                    phoneNumber: e.target.value,
                  })
                }
              />
            </FormInputCustom>
          </Col>
        </Row>
        {checkoutAsGuest && (
          <Row gutter={10}>
            <Col span={24}>
              <FormInputCustom label='Email' required>
                <Input
                  style={{ width: '100%' }}
                  size='large'
                  value={dataCustomer.email}
                  onChange={(e) =>
                    setDataCustomer({ ...dataCustomer, email: e.target.value })
                  }
                />
              </FormInputCustom>
            </Col>
          </Row>
        )}
        <Row gutter={10}>
          <Col span={24}>
            <FormInputCustom label=' Delivery Instruction (Write your delivery instruction here)'>
              <TextArea
                placeholder='Write your address note here'
                rows={5}
                value={dataCustomer.addressNote}
                onChange={(e) =>
                  setDataCustomer({
                    ...dataCustomer,
                    addressNote: e.target.value,
                  })
                }
              />
            </FormInputCustom>
          </Col>
        </Row>
      </div>

      <div className='checkout-content-header'>
        <h3>Delivery Information</h3>
      </div>

      <div className='checkout-delivery-timeframes'>
        {deliveryTimeframes.map((item, idx) => (
          <DeliveryTimeframe
            key={idx}
            changeDeliveryTimeframe={(e) => {
              handleChooseDeliveryTimeFrame({
                time: e.target.value,
                scheduleAt: item.scheduleAt,
              });
            }}
            date={item.scheduleAt}
            range={item.timeframes}
            selectedTimeframe={item.selectedTimeframe}
            scheduleAt={item.scheduleAt}
          />
        ))}
      </div>

      <Row gutter={10} style={{ marginTop: 20 }}>
        <Col span={24}>
          <FormInputCustom label='Additional Information (Write any other information you would like to communicate here)'>
            <TextArea
              placeholder='Write your delivery instruction here'
              rows={5}
              value={dataCustomer.deliveryInformation}
              onChange={(e) =>
                setDataCustomer({
                  ...dataCustomer,
                  deliveryInformation: e.target.value,
                })
              }
            />
          </FormInputCustom>
        </Col>
      </Row>

      <Checkbox
        checked={isAgree}
        className='checkout-agreement'
        onChange={(e) => setIsAgree(e.target.checked)}
      >
        I agree to the{' '}
        <Link target='_blank' to='/terms-and-conditions'>
          Terms and conditions
        </Link>{' '}
        &{' '}
        <Link target='_blank' to='/privacy-policy'>
          Privacy Policy
        </Link>
      </Checkbox>

      <AppButton
        loading={props.isLoading || loadingConstructCart}
        onClick={() => {
          coords &&
            props.onCheckout({
              ...dataCustomer,
              coords,
              state: currState || '-',
              suburb: currSuburb || '-',
              postcode: currPostcode || '-',
              deliveryTimeframes: deliveryTimeframes.filter(
                (item) => item.selectedTimeframe && item
              ),
            });
        }}
        block
        disabled={isDisabled()}
        type='primary'
        style={{ marginTop: 20, marginBottom: 10 }}
      >
        Pay Now
      </AppButton>

      <Modal
        title='Choose Addresses'
        visible={showModalAddresses}
        footer={null}
        onCancel={() => setShowModalAddresses(false)}
      >
        {myAddresses && myAddresses.length > 0 ? (
          myAddresses.map((item, idx) => (
            <div
              onClick={() => handleSelectAddress(item)}
              key={idx}
              style={{
                cursor: 'pointer',
                padding: '15px 0',
                borderBottom: '1px solid rgba(0,0,0,0.03)',
              }}
            >
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <div style={{ marginRight: 10, fontWeight: 600, fontSize: 16 }}>
                  {item.label}
                </div>
                {item.isMainAddress && <Tag>Main Address</Tag>}
              </div>
              <div>
                {item.address}, {item.suburb}, {item.postCode}, {item.state}
              </div>
            </div>
          ))
        ) : (
          <div
            style={{
              opacity: 0.5,
              textAlign: 'center',
            }}
          >
            Address Empty
          </div>
        )}
      </Modal>
    </div>
  );
};

type DeliveryTimeframeType = {
  date: string;
  range: TimeframesProps[];
  changeDeliveryTimeframe: (e: RadioChangeEvent) => void;
  selectedTimeframe: string;
  scheduleAt: string;
};

const DeliveryTimeframe: React.FC<DeliveryTimeframeType> = (props) => {
  return (
    <div>
      <p style={{ fontSize: 14 }}>
        Choose delivery date for{' '}
        <span style={{ color: '#FF4A50', fontWeight: 600 }}>
          {moment(props.date).format('ddd, MMM DD YYYY')}
        </span>
      </p>
      {props.range.map((item, idx) => (
        <Radio
          style={{ marginBottom: 20 }}
          onChange={(e) => props.changeDeliveryTimeframe(e)}
          checked={
            item.dateRaw === props.selectedTimeframe &&
            props.scheduleAt === props.date
          }
          value={item.dateRaw}
          key={idx}
        >
          {item.dateFormat} (8am - 4pm)
        </Radio>
      ))}
    </div>
  );
};

export default CheckoutDelivery;
